/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { useAccommodationDropdownQuery } from "features/accommodation/accommodationApiSlice";
import { useAccommodationTypeDropdownQuery } from "features/accommodationType/accommodationTypeApiSlice";
import { useAccommodationUnitTypeDropdownQuery } from "features/accommodationUnitType/accommodationUnitTypeApiSlice";
import { useAgencyPartnerDropdownQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { useServiceDropdownQuery } from "features/service/serviceApiSlice";
import { useTransportationTypeDropdownQuery } from "features/transportationType/transportationTypeApiSlice";
import { useUnitPropertyDropdownQuery } from "features/unitProperty/unitPropertyApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomSelectFilter from "../CustomSelectFilter";
import CustomAutoCompleteFilter from "../CustomAutoCompleteFilter";

const InternalSaleFilter = ({ filterForm, appliedFilters }) => {
  const { data: travelOrganizers } = useAgencyPartnerDropdownQuery();
  const { data: offerTypes } = useOfferTypeDropdownQuery();
  const { data: accommodations } = useAccommodationDropdownQuery({});
  const { data: accommodationTypes } = useAccommodationTypeDropdownQuery();
  const { data: accommodationUnitTypes } =
    useAccommodationUnitTypeDropdownQuery();
  const { data: transportationTypes } = useTransportationTypeDropdownQuery();
  const { data: services } = useServiceDropdownQuery();
  const { data: unitProperties } = useUnitPropertyDropdownQuery();
  const { t } = useTranslation();

  const offerStatuses = [
    { value: true, text: t("dropdown.activeOffer") },
    { value: false, text: t("dropdown.inactiveOffer") },
  ];

  const resetCountryFilterValue = () => {
    filterForm.setFieldValue("CountryId", "");
    filterForm.setFieldValue("LocationId", "");
  };

  return (
    <Box component="form">
      <RowContainer>
        <CustomSelectFilter
          name="TravelOrganizerId"
          label={t("field.travelOrganizerPlaceholder")}
          filterForm={filterForm}
          items={travelOrganizers}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="OfferNameId"
          label={t("field.offerNamePlaceholder")}
          filterForm={filterForm}
          items={[]}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomAutoCompleteFilter
          name="CountryId"
          label={t("field.countryPlaceholder")}
          filterForm={filterForm}
          useQuery={useCountryDropdownQuery}
          resetFilterValue={resetCountryFilterValue}
          appliedFilters={appliedFilters}
          style={{ marginRight: "24px" }}
        />
        <CustomSelectFilter
          name="OfferTypeId"
          label={t("field.offerTypePlaceholder")}
          filterForm={filterForm}
          items={offerTypes}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomAutoCompleteFilter
          name="LocationId"
          label={t("field.locationPlaceholder")}
          filterForm={filterForm}
          disabled={!filterForm.values.CountryId}
          appliedFilters={appliedFilters}
          useQuery={() =>
            useLocationDropdownQuery(
              { id: filterForm.values.CountryId },
              { skip: !filterForm.values.CountryId }
            )
          }
          style={{ marginRight: "24px" }}
        />
        <CustomSelectFilter
          name="AccommodationTypeId"
          label={t("field.accommodationTypeIdPlaceholder")}
          filterForm={filterForm}
          items={accommodationTypes}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="AccommodationId"
          label={t("field.accommodationIdPlaceholder")}
          filterForm={filterForm}
          items={accommodations}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="UnitPropertyId"
          label={t("field.unitPropertyIdPlaceholder")}
          filterForm={filterForm}
          items={unitProperties}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="AccommodationUnitTypeId"
          label={t("field.accommodationUnitTypePlaceholderFilter")}
          filterForm={filterForm}
          items={accommodationUnitTypes}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="ServiceId"
          label={t("field.serviceIdPlaceholder")}
          filterForm={filterForm}
          items={services}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="TransportationTypeId"
          label={t("field.transportationTypePlaceholder")}
          filterForm={filterForm}
          items={transportationTypes}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="Active"
          label={t("field.offerStatusPlaceholder")}
          filterForm={filterForm}
          items={offerStatuses}
          InputLabelProps={{
            shrink: filterForm.values.Active !== "",
          }}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default InternalSaleFilter;
