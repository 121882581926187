import { apiSlice } from "features/api/apiSlice";

export const accommodationApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Accommodation, AccommodationSingle"],
  endpoints: (builder) => ({
    accommodationDropdown: builder.query({
      query: (args) => {
        return {
          url: "/accommodation/dropdown?" + new URLSearchParams({
            ...(args && { ...args }),
          }),
        };
      },
      providesTags: ["Accommodation"],
    }),
    accommodationList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort, filter } = args;
        return {
          url:
            "/accommodation/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
              ...(filter && filter),
            }),
        };
      },
      providesTags: ["Accommodation"],
    }),
    accommodationDetails: builder.query({
      query: ({ id }) => ({
        url: `/accommodation/${id}`,
      }),
      providesTags: ["AccommodationSingle"],
    }),
    addAccommodation: builder.mutation({
      query: (data) => ({
        url: "/accommodation/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Accommodation"],
    }),
    editAccommodation: builder.mutation({
      query: (data) => ({
        url: "/accommodation/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Accommodation", "AccommodationSingle"],
    }),
    deleteAccommodation: builder.mutation({
      query: (data) => ({
        url: "/accommodation",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Accommodation"],
    }),
  }),
});

export const {
  useAccommodationDropdownQuery,
  useAccommodationListQuery,
  useAccommodationDetailsQuery,
  useAddAccommodationMutation,
  useEditAccommodationMutation,
  useDeleteAccommodationMutation,
} = accommodationApiSlice;
