import React from "react";
import { salesPageNavigationLinks } from "constants/navigationConstants";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import Title from "components/Title/Title";
import PageCard from "components/Cards/PageCard/PageCard";
import { SalesPageCardContainer } from "./SalesPage.styled";

const SalesPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Title title={t("pages.sales")} />
      <SalesPageCardContainer>
        {salesPageNavigationLinks.map((link) => (
          <PageCard key={link.title} path={link.path} Icon={link.icon}>
            {link.title}
          </PageCard>
        ))}
      </SalesPageCardContainer>
    </PageContainer>
  );
};

export default SalesPage;
