import { Autocomplete, Box } from "@mui/material";
import DatePick from "components/Inputs/DatePick";
import TextInput from "components/Inputs/TextInput";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import ClientSelect from "components/Inputs/ClientSelect";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const ReceiptForm = ({ formik }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isValidInput = (value) => {
    const regex = /^(?!.*\..*\.)[1-9][0-9]*(\.[0-9]{0,2})?$/;
    return regex.test(value);
  };

  const safeSum = (...values) => {
    return values
      ? parseFloat(values.reduce((sum, value) => sum + (Number(value) || 0), 0))
      : 0;
  };

  const contractNumbers = [
    { value: 1, text: "000-121" },
    { value: 2, text: "000-122" },
    { value: 3, text: "000-123" },
    { value: 4, text: "000-124" },
    { value: 5, text: "000-125" },
  ];

  useEffect(() => {
    const totalAmount = parseFloat(
      safeSum(
        formik.values.cashAmount,
        formik.values.cardAmount,
        formik.values.checkAmount
      ).toFixed(2)
    );
    formik.setFieldValue("totalAmount", totalAmount);
  }, [
    formik.values.cashAmount,
    formik.values.cardAmount,
    formik.values.checkAmount,
  ]);

  const handleCancel = () => {
    navigate(PAGES.RECEIPTS.route);
  };

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("payment.sections.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="receiptNumber"
            label={t("field.receiptNumberPlaceholderRequired")}
            value={formik?.values?.receiptNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.receiptNumber &&
              Boolean(formik.errors.receiptNumber)
            }
            helperText={
              formik.touched.receiptNumber && formik.errors.receiptNumber
            }
            style={{ marginRight: "24px" }}
          />
          <DatePick
            name="date"
            label={t("field.termDate")}
            value={formik?.values?.date}
            onChange={(value) => formik.setFieldValue("date", value, true)}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
            style={{ marginRight: "24px" }}
          />
          <ClientSelect
            name="clientId"
            label={t("field.clientNameAndSurnamePlaceholder")}
            value={formik?.values?.clientId}
            error={
              formik?.touched?.clientId && Boolean(formik?.errors?.clientId)
            }
            helperText={formik?.touched?.clientId && formik?.errors?.clientId}
            style={{ marginRight: "24px" }}
            onChange={(event, newValue) => {
              formik.setFieldValue("clientId", newValue ? newValue.value : "");
            }}
          />
          <TextInput
            fullWidth
            name="description"
            label={t("field.travelDescriptionPlaceholder")}
            value={formik?.values?.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            style={{ marginRight: "24px" }}
          />
          <Autocomplete
            fullWidth
            id="contractNumber"
            options={contractNumbers}
            getOptionLabel={(option) => option.text}
            value={
              contractNumbers.find(
                (option) => option.value === formik.values.contractNumber
              ) || null
            }
            onChange={(event, newValue) => {
              formik.setFieldValue(
                "contractNumber",
                newValue ? newValue.value : ""
              );
            }}
            renderInput={(params) => (
              <TextInput
                {...params}
                name="contractNumber"
                label={t("field.contractNumber")}
                error={
                  formik.touched.contractNumber &&
                  Boolean(formik.errors.contractNumber)
                }
                helperText={
                  formik.touched.contractNumber && formik.errors.contractNumber
                }
              />
            )}
          />
        </RowContainer>
      </FormContainer>

      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("payment.sections.paymentInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="cashAmount"
            label={t("field.cashAmountPlaceholder")}
            value={formik?.values?.cashAmount}
            type="number"
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "" || isValidInput(inputValue)) {
                formik.setFieldValue(`cashAmount`, parseFloat(inputValue));
              }
            }}
            error={
              formik.touched.cashAmount &&
              (Boolean(formik.errors.cashAmount) ||
                Boolean(formik.errors.atLeastOneAmountRequired))
            }
            helperText={
              (formik.touched.cashAmount && formik.errors.cashAmount) ||
              (formik.touched.cashAmount &&
                formik.errors.atLeastOneAmountRequired)
            }
            style={{ marginRight: "24px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextInput
            fullWidth
            name="cardAmount"
            label={t("field.cardAmountPlaceholder")}
            value={formik?.values?.cardAmount}
            type="number"
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "" || isValidInput(inputValue)) {
                formik.setFieldValue(`cardAmount`, parseFloat(inputValue));
              }
            }}
            error={
              formik.touched.cardAmount &&
              (Boolean(formik.errors.cardAmount) ||
                Boolean(formik.errors.atLeastOneAmountRequired))
            }
            helperText={
              (formik.touched.cardAmount && formik.errors.cardAmount) ||
              (formik.touched.cardAmount &&
                formik.errors.atLeastOneAmountRequired)
            }
            style={{ marginRight: "24px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextInput
            fullWidth
            name="checkAmount"
            type="number"
            label={t("field.checkAmountPlaceholder")}
            value={formik?.values?.checkAmount}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "" || isValidInput(inputValue)) {
                formik.setFieldValue(`checkAmount`, parseFloat(inputValue));
              }
            }}
            error={
              formik.touched.checkAmount &&
              (Boolean(formik.errors.checkAmount) ||
                Boolean(formik.errors.atLeastOneAmountRequired))
            }
            helperText={
              (formik.touched.checkAmount && formik.errors.checkAmount) ||
              (formik.touched.checkAmount &&
                formik.errors.atLeastOneAmountRequired)
            }
            style={{ marginRight: "24px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextInput
            fullWidth
            disabled
            type="number"
            name="totalAmount"
            label={t("field.totalAmount")}
            value={formik?.values?.totalAmount}
            error={
              formik.touched.totalAmount && Boolean(formik.errors.totalAmount)
            }
            helperText={formik.touched.totalAmount && formik.errors.totalAmount}
            style={{ marginRight: "24px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextInput
            fullWidth
            name="dueAmount"
            label={t("field.restAmount")}
            value={formik?.values?.dueAmount}
            onChange={formik.handleChange}
            error={formik.touched.dueAmount && Boolean(formik.errors.dueAmount)}
            helperText={formik.touched.dueAmount && formik.errors.dueAmount}
          />
        </RowContainer>
      </FormContainer>
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("payment.sections.noteInfo")}
          </InformationMainText>
        </InformationContainer>
        <TextInput
          name="receiptNotice"
          value={formik.values.receiptNotice}
          onChange={formik.handleChange}
          multiline
          minRows={5}
          label={t("settings.printTab.defaultTextForReceiptNotes")}
          style={{ marginBottom: 24 }}
        />
      </FormContainer>
      <FormActions
        disabled={!formik.dirty}
        handleCancel={handleCancel}
        handleConfirm={formik.handleSubmit}
      />
    </Box>
  );
};
ReceiptForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ReceiptForm;
