import { PAGES } from "./pages";
import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DescriptionIcon from "@mui/icons-material/Description";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SourceIcon from "@mui/icons-material/Source";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { CLERK_ROLE, MANAGER_ROLE, SUPERADMIN_ROLE } from "./roleConstants";
import { OTP_TYPE } from "./agencyType";

export const sidebarItems = [
  {
    title: PAGES.BASE.title,
    path: PAGES.BASE.route,
    Icon: HomeIcon,
    role: [],
    agencyType: [],
  },
  {
    title: PAGES.CLIENTS.title,
    path: PAGES.CLIENTS.route,
    Icon: PeopleAltIcon,
    role: [MANAGER_ROLE, CLERK_ROLE],
    agencyType: [],
  },
  {
    title: PAGES.PARTNERS.title,
    path: PAGES.PARTNERS.route,
    Icon: HandshakeIcon,
    role: [MANAGER_ROLE, CLERK_ROLE],
    agencyType: [],
  },
  {
    title: PAGES.CODEBOOK.title,
    Icon: SourceIcon,
    path: "/codebook",
    role: [],
    agencyType: [],
    children: [
      {
        items: [
          {
            title: PAGES.CATEGORY.title,
            path: PAGES.CATEGORY.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
          {
            title: PAGES.COUNTRY.title,
            path: PAGES.COUNTRY.route,
            role: [],
            agencyType: [],
          },
          {
            title: PAGES.LOCATION.title,
            path: PAGES.LOCATION.route,
            role: [],
            agencyType: [],
          },
          {
            title: PAGES.ACCOMMODATION_TYPE.title,
            path: PAGES.ACCOMMODATION_TYPE.route,
            role: [],
            agencyType: [],
          },
          {
            title: PAGES.ACCOMMODATION.title,
            path: PAGES.ACCOMMODATION.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
          {
            title: PAGES.ACCOMMODATION_UNIT_TYPE.title,
            path: PAGES.ACCOMMODATION_UNIT_TYPE.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
          {
            title: PAGES.ACCOMMODATION_UNIT_PROPERTY.title,
            path: PAGES.ACCOMMODATION_UNIT_PROPERTY.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
          {
            title: PAGES.SERVICES.title,
            path: PAGES.SERVICES.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
          {
            title: PAGES.TRANSPORTATION_TYPE.title,
            path: PAGES.TRANSPORTATION_TYPE.route,
            role: [],
            agencyType: [],
          },
          {
            title: PAGES.OFFER_TYPE.title,
            path: PAGES.OFFER_TYPE.route,
            role: [],
            agencyType: [],
          },
          {
            title: PAGES.CURRENCY.title,
            path: PAGES.CURRENCY.route,
            role: [SUPERADMIN_ROLE],
            agencyType: [],
          },
        ],
      },
    ],
  },
  {
    title: PAGES.OFFERS.title,
    path: PAGES.OFFERS.route,
    Icon: LocalOfferIcon,
    role: [MANAGER_ROLE, CLERK_ROLE],
    agencyType: [OTP_TYPE],
  },
  {
    title: PAGES.SALES.title,
    path: PAGES.SALES.route,
    Icon: ShoppingCartIcon,
    role: [MANAGER_ROLE, CLERK_ROLE],
    agencyType: [],
    children: [
      {
        items: [
          {
            title: PAGES.INTERNAL_SALE.title,
            path: PAGES.INTERNAL_SALE.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
          {
            title: PAGES.EXTERNAL_SALE.title,
            path: PAGES.EXTERNAL_SALE.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
        ],
      },
    ],
  },
  {
    title: PAGES.CONTRACTS.title,
    path: PAGES.CONTRACTS.route,
    Icon: HistoryEduIcon,
    role: [MANAGER_ROLE, CLERK_ROLE],
    agencyType: [],
  },
  {
    title: PAGES.PAYMENT.title,
    path: PAGES.PAYMENT.route,
    Icon: AttachMoneyIcon,
    role: [MANAGER_ROLE, CLERK_ROLE],
    agencyType: [],
    children: [
      {
        items: [
          {
            title: PAGES.RECEIPTS.title,
            path: PAGES.RECEIPTS.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
          {
            title: PAGES.INVOICE.title,
            path: PAGES.INVOICE.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
          {
            title: PAGES.PROINVOICE.title,
            path: PAGES.PROINVOICE.route,
            role: [MANAGER_ROLE, CLERK_ROLE],
            agencyType: [],
          },
        ],
      },
    ],
  },
  {
    title: PAGES.STATEMENTS.title,
    path: PAGES.STATEMENTS.route,
    Icon: DescriptionIcon,
    role: [MANAGER_ROLE, CLERK_ROLE],
    agencyType: [],
  },
  {
    title: PAGES.REPORTS.title,
    path: PAGES.REPORTS.route,
    Icon: FormatAlignLeftIcon,
    role: [MANAGER_ROLE],
    agencyType: [],
  },
  {
    title: PAGES.NOTIFICATIONS.title,
    path: PAGES.NOTIFICATIONS.route,
    Icon: NotificationsIcon,
    role: [MANAGER_ROLE, CLERK_ROLE],
    agencyType: [],
  },
  {
    title: PAGES.EXCHANGE_RATE.title,
    path: PAGES.EXCHANGE_RATE.route,
    role: [SUPERADMIN_ROLE],
    Icon: CurrencyExchangeIcon,
    agencyType: [],
  },
];
