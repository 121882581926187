import React from "react";
import { PageContainer } from "styles/SharedStyle.styled";
import { useTemplateAgencyInfoGetByAgencyQuery } from "features/templateAgencyInfo/templateAgencyInfoApiSlice";
import PrintForm from "components/Forms/PrintForm/PrintForm";
import { useSelector } from "react-redux";
import { selectCurrentAgency } from "features/auth/authSlice";

const ProfilePage = () => {
  const agencyId = useSelector(selectCurrentAgency);
  const { data: data, isLoading: isLoading } =
    useTemplateAgencyInfoGetByAgencyQuery({agencyId: agencyId});
  return (
    <PageContainer>
      <PrintForm data={data} isLoading={isLoading} />
    </PageContainer>
  );
};

export default ProfilePage;
