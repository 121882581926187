/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomTextFilter from "../CustomTextFilter";

const BankAccountFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name="BankName"
          label={t("field.bankNameFilter")}
          filterForm={filterForm}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomTextFilter
          name="AccountNumber"
          label={t("field.accountNumberFilter")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default BankAccountFilter;
