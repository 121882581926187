import { apiSlice } from "features/api/apiSlice";

export const statementItemApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["StatementItem", "StatementItemSingle"],
  endpoints: (builder) => ({
    statementItemDropdown: builder.query({
      query: () => ({
        url: "/statement-item/dropdown",
      }),
      providesTags: ["StatementItem"],
    }),
    statementItemList: builder.query({
      query: (args) => {
        const { statementId } = args;
        return {
          url:
            "/statement-item/list?" +
            new URLSearchParams({
              StatementId: statementId,
              Active: true,
            }),
        };
      },
      providesTags: ["StatementItem"],
    }),
    statementItemDetails: builder.query({
      query: ({ id }) => ({
        url: `/statement-item/${id}`,
      }),
      providesTags: ["StatementItemSingle"],
    }),
    addStatementItem: builder.mutation({
      query: (data) => ({
        url: "/statement-item/save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["StatementItem"],
    }),
    editStatementItem: builder.mutation({
      query: (data) => ({
        url: "/statement-item/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["StatementItem", "StatementItemSingle"],
    }),
    deleteStatementItem: builder.mutation({
      query: (data) => ({
        url: "/statement-item",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["StatementItem"],
    }),
  }),
});

export const {
  useStatementItemDropdownQuery,
  useStatementItemListQuery,
  useStatementItemDetailsQuery,
  useAddStatementItemMutation,
  useEditStatementItemMutation,
  useDeleteStatementItemMutation,
} = statementItemApiSlice;
