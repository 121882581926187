import { Autocomplete } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import TextInput from "./TextInput";
import { useClientDropdownQuery } from "features/client/clientApiSlice";

const ClientSelect = ({
  name,
  style,
  value,
  onChange,
  label,
  error,
  helperText,
}) => {
  const { data: clients, isLoading: isLoadingClients } =
    useClientDropdownQuery();

  return (
    <Autocomplete
      fullWidth
      style={style}
      id={name}
      options={!isLoadingClients ? clients : []}
      getOptionLabel={(option) => option.text}
      value={
        !isLoadingClients
          ? clients?.find((option) => option.value === value) || null
          : null
      }
      onChange={onChange}
      componentsProps={{
        popper: { style: { minWidth: "fit-content" } },
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          name={name}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};
ClientSelect.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default ClientSelect;
