import { Box } from "@mui/material";
import styled from "styled-components";

export const StatementItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 24px;
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

export const StatementItemRemoveAction = styled(Box)`
  align-self: flex-end;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    align-self: center;
    margin-bottom: 0;
  }
`;
