import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import tooltipPlacements from "constants/tooltipPlacements";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const CustomAutoCompleteFilter = (props) => {
  const { t } = useTranslation();
  const { data: items, isLoading: isLoadingItems } = props.useQuery();
  const [open, setOpen] = useState(false);
  const onOpen = () => {
    if (props?.disabled) {
      return;
    }
    setOpen(true);
  };
  const onClose = () => {
    if (props?.disabled) {
      return;
    }
    setOpen(false);
  };
  const onEndArrowIconClick = () => {
    if (props?.disabled) return;
    setOpen((oldValue) => !oldValue);
  };

  const resetFilterValue = () => {
    if (props?.filterForm?.values?.[props?.name] !== "" && !props?.disabled) {
      props?.filterForm.setFieldValue(props.name, "");
      props?.resetFilterValue?.();
      if (
        props?.appliedFilters?.[props?.name] &&
        props?.appliedFilters?.[props?.name] !== ""
      ) {
        props?.filterForm.handleSubmit();
      }
    }
  };

  return (
    <Autocomplete
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      fullWidth
      style={props?.style}
      name={props?.name}
      id={props?.name}
      disabled={props?.disabled}
      options={!isLoadingItems ? items : []}
      getOptionLabel={(option) => option.text}
      value={
        !isLoadingItems
          ? items?.find(
              (option) =>
                option.value === props?.filterForm?.values?.[props?.name]
            ) || null
          : null
      }
      onChange={(event, newValue) => {
        props?.filterForm?.setFieldValue(
          props?.name,
          newValue ? newValue.value : ""
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props?.name}
          label={props?.label}
          error={props?.error}
          helperText={props?.helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton
                    disabled={props?.disabled}
                    onClick={onEndArrowIconClick}
                    sx={{
                      padding: 0,
                      width: 20,
                      height: 20,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "& .MuiTouchRipple-root": {
                        display: "none",
                      },
                    }}
                  >
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </IconButton>
                  <IconButton disabled={props?.disabled}>
                    <Tooltip
                      title={t("common.resetFilter")}
                      placement={tooltipPlacements.TOP}
                    >
                      <RestartAltIcon onClick={resetFilterValue} />
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
      sx={{
        "& .MuiOutlinedInput-root": {
          paddingRight: "14px !important",
        },
        "& .MuiAutocomplete-inputRoot": {
          paddingRight: "14px !important",
        },
      }}
    />
  );
};
CustomAutoCompleteFilter.propTypes = {
  name: PropTypes.string.isRequired,
  filterForm: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  appliedFilters: PropTypes.array,
  useQuery: PropTypes.func,
  resetFilterValue: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CustomAutoCompleteFilter;
