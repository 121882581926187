import { Button } from "@mui/material";
import BreadcrumbsComponent from "components/Breadcrumbs/BreadcrumbsComponent";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Search/Search";
import Title from "components/Title/Title";
import modalConstants from "constants/modalConstants";
import { PAGES } from "constants/pages";
import { closeModal, openModal } from "features/modal/modalSlice";
import {
  useAddTransportationTypeMutation,
  useDeleteTransportationTypeMutation,
  useEditTransportationTypeMutation,
  useTransportationTypeListQuery,
} from "features/transportationType/transportationTypeApiSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import useDebounce from "hooks/useDebounceHook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const TransportationTypePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "name", direction: "asc" });
  const { columns } = useCodebookTable({
    page,
    rowsPerPage,
  });

  const debouncedSearch = useDebounce(search);

  const { data: transportationType, isLoading } =
    useTransportationTypeListQuery({
      page,
      rowsPerPage,
      search: debouncedSearch,
      sort,
    });
  const [addTransportationType, { isLoading: isLoadingAddService }] =
    useAddTransportationTypeMutation();

  const [editTransportationType, { isLoading: isLoadingEditService }] =
    useEditTransportationTypeMutation();

  const [deleteTransportationType, { isLoading: isLoadingDeleteService }] =
    useDeleteTransportationTypeMutation();

  const handleSubmit = (val) => {
    if (addModal?.open) {
      const { name } = val;
      addTransportationType({ name })
        .unwrap()
        .then(() => {
          if (!isLoadingAddService) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
            makeToastMessage(t("transportationType.addSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("transportationType.addErrorMessage"))
        );
    }

    if (editModal?.open) {
      const { id, name } = val;

      editTransportationType({ name, id })
        .unwrap()
        .then(() => {
          if (!isLoadingEditService) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
            makeToastMessage(t("transportationType.editSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("transportationType.editErrorMessage"))
        );
    }
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.data) {
      deleteTransportationType({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteService) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("transportationType.deleteSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("transportationType.deleteErrorMessage"))
        );
    }
  };

  const formik = useFormik({
    initialValues: editModal?.open ? editModal?.data : { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.transportationTypeRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    dispatch(openModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const breadcrumbs = [
    { title : t("breadcrumbs.home"), route: PAGES.BASE.route },
    { title : t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
    { title : t("breadcrumbs.transportationType"), route: '' },
  ];

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("transportationType.editTitle")}
        addTitle={t("transportationType.addTitle")}
        labelTitle={t("field.transportatopnTypeIdPlaceholderRequired")}
        confirmCloseDescription={t("transportationType.confirmClose")}
        confirmDeleteDescription={t("transportationType.confirmDelete")}
        handleConfirmDelete={handleConfirmDelete}
      />

      <Title title={t("transportationType.pageTitle")} />
      <BreadcrumbsComponent customBreadcrumbs={breadcrumbs} />
      <PageHeader>
        <Search
          search={search}
          handleChange={handleSearch}
          label={t("transportationType.searchPlaceholder")}
        />
        <Button variant="contained" onClick={handleOpenAdd}>
          {t("transportationType.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={transportationType?.data}
        isLoading={isLoading}
        count={transportationType?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("transportationType.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default TransportationTypePage;
