import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object()
  .shape({
    receiptNumber: Yup.string().required(
      i18next.t("field.receiptNumberRequired")
    ),
    clientId: Yup.string().required(i18next.t("field.nameRequired")),
    description: Yup.string().required(i18next.t("field.descriptionRequired")),
    cashAmount: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    cardAmount: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    checkAmount: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    totalAmount: Yup.number().required(i18next.t("field.totalAmountRequired")),
    date: Yup.date()
      .required(i18next.t("field.dateOfTermRequired"))
      .typeError(i18next.t("field.dateFormatTypeError")),
  })
  .test(
    "at-least-one-amount",
    i18next.t("field.atLeastOneAmountRequired"),
    function (values) {
      if (values.cashAmount || values.cardAmount || values.checkAmount) {
        return true;
      }
      return this.createError({
        path: "cashAmount", // You can set this to any of the three fields
        message: i18next.t("field.atLeastOneAmountRequired"),
      });
    }
  );
