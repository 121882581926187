/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import React from "react";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomTextFilter from "../CustomTextFilter";
import { useTranslation } from "react-i18next";
import CustomAutoCompleteFilter from "../CustomAutoCompleteFilter";

const LocationFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();

  const resetCountryFilterValue = () => {
    filterForm.setFieldValue("CountryId", "");
    filterForm.setFieldValue("Name", "");
  };

  return (
    <Box component="form">
      <RowContainer></RowContainer>
      <RowContainer>
        <CustomAutoCompleteFilter
          name="CountryId"
          label={t("field.countryPlaceholder")}
          filterForm={filterForm}
          useQuery={useCountryDropdownQuery}
          resetFilterValue={resetCountryFilterValue}
          appliedFilters={appliedFilters}
          style={{ marginRight: "24px" }}
        />
        <CustomTextFilter
          name="Name"
          label={t("field.locationPlaceholder")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default LocationFilter;
