/* eslint-disable react/prop-types */
import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, Popover, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useTranslation } from "react-i18next";
import { FilterActionsContainer, FilterTopContainer } from "./Filter.styled";

const Filter = ({ handleReset, children, filterForm }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitFilters = () => {
    filterForm.handleSubmit();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box style={{ marginRight: 24 }}>
      <Button onClick={handleClick} aria-describedby={id}>
        <FilterListIcon />
        {t("common.filter")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box style={{ padding: 16, width: 750 }}>
          <FilterTopContainer>
            <Tooltip
              title={t("common.resetFilters")}
              placement="top"
              style={{ marginRight: 5 }}
            >
              <Button onClick={handleReset}>
                <RestartAltIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t("common.close")} placement="top">
              <Button onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Tooltip>
          </FilterTopContainer>
          {children}
          <FilterActionsContainer>
            <Button style={{ marginRight: 24 }} onClick={handleClose}>
              {t("common.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmitFilters}
            >
              {t("common.apply")}
            </Button>
          </FilterActionsContainer>
        </Box>
      </Popover>
    </Box>
  );
};

export default Filter;
