import { PAGES } from "constants/pages";
import {
  useAddClientMutation,
  useClientDetailsQuery,
  useEditClientMutation,
  useGetClientPassportImageQuery,
} from "features/client/clientApiSlice";
import { useFormik } from "formik";
import clientInitialValues from "initialValues/clientInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import clientValidation from "validations/clientValidation";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import Error from "pages/ErrorPage/ErrorPage";
import Title from "components/Title/Title";
import ClientForm from "components/Forms/ClientForm/ClientForm";
import BreadcrumbsComponent from "components/Breadcrumbs/BreadcrumbsComponent";

const ClientAddEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDCLIENT.route;
  const navigate = useNavigate();

  const {
    data: clientDetails,
    isLoading: isLoadingClientDetails,
    error,
  } = useClientDetailsQuery({ id: id }, { skip: !id || isAdd });

  const { data: clientPassportImage, isLoading: isLoadingClientPassportImage } =
    useGetClientPassportImageQuery({ id }, { skip: !id || isAdd });

  const [addClient, { isLoading: isLoadingAddClient }] = useAddClientMutation();
  const [editClient, { isLoading: isLoadingEditClient }] =
    useEditClientMutation();

  const handleSubmit = async (values) => {
    if (isAdd) {
      try {
        await addClient({
          ...values,
          dateOfBirth: convertDateToISO(values.dateOfBirth),
          passportExpirationDate: values.passportExpirationDate
            ? convertDateToISO(values.passportExpirationDate)
            : null,
        }).unwrap();
        if (!isLoadingAddClient) {
          navigate(PAGES.CLIENTS.route);
          makeToastMessage(t("client.addSuccessMessage"));
        }
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }

    if (id) {
      try {
        await editClient({
          ...values,
          dateOfBirth: convertDateToISO(values.dateOfBirth),
          passportExpirationDate: values.passportExpirationDate
            ? convertDateToISO(values.passportExpirationDate)
            : null,
        })
          .unwrap()
          .then(() => {
            if (!isLoadingEditClient) {
              navigate(PAGES.CLIENTS.route);
              makeToastMessage(t("client.editSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues:
      id && !isLoadingClientDetails && !isLoadingClientPassportImage
        ? {
            ...clientDetails,
            dateOfBirth:
              clientDetails?.dateOfBirth !== null
                ? new Date(clientDetails?.dateOfBirth)
                : null,
            passportExpirationDate:
              clientDetails?.passportExpirationDate !== null
                ? new Date(clientDetails?.passportExpirationDate)
                : null,
            passportImage:
              clientPassportImage?.data !== null
                ? [clientPassportImage?.data]
                : null,
          }
        : clientInitialValues,
    validationSchema: clientValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (isLoadingClientDetails) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingClientDetails}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }
  return (
    <PageContainer>
      <Title title={id ? t("client.editTitle") : t("client.addTitle")} />
      <BreadcrumbsComponent />
      <ClientForm formik={formik} id={id} isAdd={isAdd} />
    </PageContainer>
  );
};

export default ClientAddEditPage;
