import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import { useTranslation } from "react-i18next";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import FormActions from "../FormActions/FormActions";
import { useExchangeRateOnDateQuery } from "features/exchange-rate/exchangeRateSlice";
import { convertDateToISO, getOneDayBeforeDate } from "util/dateHelpers";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import {
  StatementItemRow,
  StatementOrdinalNumber,
} from "./StatementItemsForm.styled";
import ClientSelect from "components/Inputs/ClientSelect";
import PartnerSelect from "components/Inputs/PartnerSelect";
import { DOMESTIC_TYPE, FOREIGN_TYPE } from "constants/statementType";
import {
  StatementItemRemoveAction,
  StatementItemsContainer,
} from "./StatementItemForm.styled";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import CurrencySelect from "components/Inputs/CurrencySelect";
import { useCurrencyDropdownQuery } from "features/currency/currencyApiSlice";

const StatementItemForm = ({
  formik,
  date,
  statementId,
  statementType,
  isEdit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [statementItemIndex, setStatementItemIndex] = useState(null);
  const [statementItemCurrencyId, setStatementItemCurrencyId] = useState(null);
  const clientPartners = [
    { value: 1, text: "Partner" },
    { value: 2, text: "Klijent" },
  ];
  const statementTypes = [
    { value: 1, text: "Uplata" },
    { value: -1, text: "Isplata" },
  ];

  const isValidInput = (value, decimalPlaces = 2) => {
    const regex = new RegExp(
      `^(?!.*\\..*\\.)[1-9][0-9]*(\\.[0-9]{0,${decimalPlaces}})?$`
    );
    return regex.test(value);
  };

  const { data: currencies } = useCurrencyDropdownQuery();

  const { data: exchangeRate } =
    useExchangeRateOnDateQuery(
      {
        currencyId: formik.values.statementItems[statementItemIndex]?.currencyId,
        date: convertDateToISO(getOneDayBeforeDate(date)),
      },
      { skip: !statementItemCurrencyId }
    );

    useEffect(() => {
      if (exchangeRate || statementItemIndex) {
        formik.setFieldValue(
          `statementItems[${statementItemIndex}].exchangeRate`,
          exchangeRate?.rate
        );
      }
    }, [exchangeRate, statementItemIndex]);


  useEffect(() => {
    if (statementId) {
      formik.setFieldValue("statementId", statementId, false);
      formik.values.statementItems.forEach((item, index) => {
        if (!item.statementId) {
          formik.setFieldValue(
            `statementItems[${index}].statementId`,
            statementId,
            false
          );
        }
      });
    }
  }, [statementId, formik.values.statementItems]);

  useEffect(() => {
    if (statementType === DOMESTIC_TYPE) {
      formik.values.statementItems.forEach((item, index) => {
        formik.setFieldValue(
          `statementItems[${index}].foreignAmount`,
          "",
          true
        );
        formik.setFieldValue(`statementItems[${index}].exchangeRate`, "", true);
        formik.setFieldValue(`statementItems[${index}].totalAmount`, "", true);
      });
    }
    if (statementType === FOREIGN_TYPE) {
      formik.values.statementItems.forEach((item, index) => {
        formik.setFieldValue(`statementItems[${index}].amount`, "", true);
      });
    }
  }, [statementType]);

  useEffect(() => {
    if (statementType === DOMESTIC_TYPE) {
      const rsdCurrency = currencies?.find(currency => currency.text.toLowerCase() === 'rsd');
      console.log(rsdCurrency);
      if (rsdCurrency) {
        formik.values.statementItems.forEach((item, index) => {
          formik.setFieldValue(
            `statementItems[${index}].currencyId`,
            rsdCurrency.value,
            false
          );
        });
      }
    }
  }, [statementType, currencies, formik.values.statementItems]);

  useEffect(() => {
    if (statementType === FOREIGN_TYPE) {
      formik.values.statementItems.forEach((item, index) => {
        if (
          formik.values.statementItems[index].foreignAmount &&
          formik.values.statementItems[index].exchangeRate &&
          !item.id
        ) {
          const total = parseFloat(
            (item.foreignAmount * item.exchangeRate).toFixed(2)
          );

          formik.setFieldValue(
            `statementItems[${index}].totalAmount`,
            total,
            false
          );
        }
      });
    }
  }, [formik.values.statementItems, statementType]);

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("statement.sections.statementItemsInfo")}
          </InformationMainText>
        </InformationContainer>
        <>
          {formik?.values.statementItems &&
            formik.values.statementItems.map((item, index) => (
              <React.Fragment key={index}>
                <StatementItemRow>
                  <StatementOrdinalNumber>
                    <TextInput
                      fullWidth
                      label="R. br."
                      value={index + 1}
                      style={{ marginRight: "24px" }}
                    />
                  </StatementOrdinalNumber>
                  <StatementItemsContainer>
                    <RowContainer
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <SelectInput
                        fullWidth
                        name={`statementItems[${index}].creditDebit`}
                        label={t("field.creditDebitPlacejholderRequired")}
                        value={
                          formik.values.statementItems[index].creditDebit || ""
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            `statementItems[${index}].invoice`,
                            ""
                          );
                          formik.setFieldValue(
                            `statementItems[${index}].creditDebit`,
                            parseInt(e.target.value),
                            true
                          );
                        }}
                        error={
                          formik.touched.statementItems?.[index]?.creditDebit &&
                          Boolean(
                            formik.errors.statementItems?.[index]?.creditDebit
                          )
                        }
                        helperText={
                          formik.touched.statementItems?.[index]?.creditDebit &&
                          formik.errors.statementItems?.[index]?.creditDebit
                        }
                        style={{ marginRight: "24px" }}
                        items={statementTypes}
                      />
                      <SelectInput
                        fullWidth
                        name={`statementItems[${index}].clientPartner`}
                        label={t("field.clientPartnerPlaceholderRequired")}
                        value={
                          formik.values.statementItems[index].clientPartner ||
                          ""
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            `statementItems[${index}].clientId`,
                            ""
                          );
                          formik.setFieldValue(
                            `statementItems[${index}].partnerId`,
                            ""
                          );
                          formik.setFieldValue(
                            `statementItems[${index}].clientPartner`,
                            e.target.value,
                            true
                          );
                        }}
                        style={{ marginRight: "24px" }}
                        error={
                          formik.touched.statementItems?.[index]
                            ?.clientPartner &&
                          Boolean(
                            formik.errors.statementItems?.[index]?.clientPartner
                          )
                        }
                        helperText={
                          formik.touched.statementItems?.[index]
                            ?.clientPartner &&
                          formik.errors.statementItems?.[index]?.clientPartner
                        }
                        items={clientPartners}
                      />
                      {formik.values.statementItems[index].clientPartner ===
                        1 && (
                        <PartnerSelect
                          name={`statementItems[${index}].partnerId`}
                          label={t("field.partnerPlaceholderRequired")}
                          value={formik.values.statementItems[index].partnerId}
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              `statementItems[${index}].partnerId`,
                              newValue ? newValue.value : null,
                              true
                            );
                          }}
                          style={{ marginRight: "24px" }}
                          error={
                            formik.touched.statementItems?.[index]?.partnerId &&
                            Boolean(
                              formik.errors.statementItems?.[index]?.partnerId
                            )
                          }
                          helperText={
                            formik.touched.statementItems?.[index]?.partnerId &&
                            formik.errors.statementItems?.[index]?.partnerId
                          }
                        />
                      )}
                      {formik.values.statementItems[index].clientPartner ===
                        2 && (
                        <ClientSelect
                          name={`statementItems[${index}].clientId`}
                          label={t("field.clientPlaceholderRequired")}
                          value={formik.values.statementItems[index].clientId}
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              `statementItems[${index}].clientId`,
                              newValue ? newValue.value : null,
                              true
                            );
                          }}
                          style={{ marginRight: "24px" }}
                          error={
                            formik.touched.statementItems?.[index]?.clientId &&
                            Boolean(
                              formik.errors.statementItems?.[index]?.clientId
                            )
                          }
                          helperText={
                            formik.touched.statementItems?.[index]?.clientId &&
                            formik.errors.statementItems?.[index]?.clientId
                          }
                        />
                      )}

                      {formik.values.statementItems[index].creditDebit ===
                        1 && (
                        <TextInput
                          fullWidth
                          name={`statementItems[${index}].invoice`}
                          label={t("field.invoiceNumberPlaceholderRequired")}
                          value={
                            formik.values.statementItems[index].invoice || ""
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              `statementItems[${index}].invoice`,
                              e.target.value
                            )
                          }
                          style={{ marginRight: "24px" }}
                          error={
                            formik.touched.statementItems?.[index]?.invoice &&
                            Boolean(
                              formik.errors.statementItems?.[index]?.invoice
                            )
                          }
                          helperText={
                            formik.touched.statementItems?.[index]?.invoice &&
                            formik.errors.statementItems?.[index]?.invoice
                          }
                        />
                      )}

                      <TextInput
                        fullWidth
                        name={`statementItems[${index}].reservationNumber`}
                        label={t("field.reservationNumberPlaceholder")}
                        value={
                          formik.values.statementItems[index]
                            .reservationNumber || ""
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            `statementItems[${index}].reservationNumber`,
                            e.target.value
                          )
                        }
                      />
                    </RowContainer>
                    <RowContainer>
                      <TextInput
                        fullWidth
                        name={`statementItems[${index}].description`}
                        label={t(
                          "field.statementDescriptionPlaceholderRequired"
                        )}
                        value={
                          formik.values.statementItems[index].description || ""
                        }
                        error={
                          formik.touched.statementItems?.[index]?.description &&
                          Boolean(
                            formik.errors.statementItems?.[index]?.description
                          )
                        }
                        helperText={
                          formik.touched.statementItems?.[index]?.description &&
                          formik.errors.statementItems?.[index]?.description
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            `statementItems[${index}].description`,
                            e.target.value
                          )
                        }
                        style={{ marginRight: "24px" }}
                      />
                      {statementType === DOMESTIC_TYPE && (
                        <TextInput
                          fullWidth
                          name={`statementItems[${index}].amount`}
                          type="number"
                          label={t("field.amountPlaceholderRequired")}
                          value={
                            formik.values.statementItems[index].amount || ""
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              `statementItems[${index}].amount`,
                              parseFloat(e.target.value)
                            )
                          }
                          error={
                            formik.touched.statementItems?.[index]?.amount &&
                            Boolean(
                              formik.errors.statementItems?.[index]?.amount
                            )
                          }
                          helperText={
                            formik.touched.statementItems?.[index]?.amount &&
                            formik.errors.statementItems?.[index]?.amount
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                RSD
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      {statementType === FOREIGN_TYPE && (
                        <>
                          <CurrencySelect
                            name={`statementItems[${index}].currencyId`}
                            label={t("field.currencyPlaceholderRequired")}
                            onChange={(e) => {
                              setStatementItemIndex(index);
                              setStatementItemCurrencyId(e.target.value);
                              formik.setFieldValue(
                                `statementItems[${index}].currencyId`,
                                e.target.value,
                                true
                              );
                            }}
                            value={
                              formik.values.statementItems[index]
                                .currencyId || ""
                            }
                            items={currencies}
                            fullWidth
                            error={
                              formik.touched.statementItems?.[index]
                                ?.currencyId &&
                              Boolean(
                                formik.errors.statementItems?.[index]
                                  ?.foreignAmount
                              )
                            }
                            helperText={
                              formik.touched.statementItems?.[index]
                                ?.currencyId &&
                              formik.errors.statementItems?.[index]
                                ?.currencyId
                            }
                           style={{ marginRight: 24 }}
                          />
                          <TextInput
                            fullWidth
                            name={`statementItems[${index}].foreignAmount`}
                            type="number"
                            label={t("field.foreignAmountPlaceholderRequired")}
                            value={
                              formik.values.statementItems[index]
                                .foreignAmount || ""
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === "" ||
                                isValidInput(inputValue)
                              ) {
                                formik.setFieldValue(
                                  `statementItems[${index}].foreignAmount`,
                                  parseFloat(inputValue)
                                );
                              }
                            }}
                            style={{ marginRight: "24px" }}
                            error={
                              formik.touched.statementItems?.[index]
                                ?.foreignAmount &&
                              Boolean(
                                formik.errors.statementItems?.[index]
                                  ?.foreignAmount
                              )
                            }
                            helperText={
                              formik.touched.statementItems?.[index]
                                ?.foreignAmount &&
                              formik.errors.statementItems?.[index]
                                ?.foreignAmount
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {
                                    currencies?.find(currency => currency.value === formik.values.statementItems[index].currencyId)?.text
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />

                          <TextInput
                            fullWidth
                            name={`statementItems[${index}].exchangeRate`}
                            type="number"
                            label={t("field.ratePlaceholderRequired")}
                            value={
                              formik.values.statementItems[index]
                                .exchangeRate || ""
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === "" ||
                                isValidInput(inputValue, 4)
                              ) {
                                formik.setFieldValue(
                                  `statementItems[${index}].exchangeRate`,
                                  parseFloat(inputValue)
                                );
                              }
                            }}
                            error={
                              formik.touched.statementItems?.[index]
                                ?.exchangeRate &&
                              Boolean(
                                formik.errors.statementItems?.[index]
                                  ?.exchangeRate
                              )
                            }
                            helperText={
                              formik.touched.statementItems?.[index]
                                ?.exchangeRate &&
                              formik.errors.statementItems?.[index]
                                ?.exchangeRate
                            }
                            style={{ marginRight: "24px" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {currencies?.find(currency => currency.text.toLowerCase() === 'rsd').text}
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextInput
                            fullWidth
                            disabled
                            name={`statementItems[${index}].totalAmount`}
                            type="number"
                            label={t("field.totalAmountPlaceholderRequired")}
                            value={
                              formik.values.statementItems[index].totalAmount ||
                              ""
                            }
                            onChange={(e) =>
                              formik.setFieldValue(
                                `statementItems[${index}].totalAmount`,
                                parseFloat(e.target.value)
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {currencies?.find(currency => currency.text.toLowerCase() === 'rsd').text}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    </RowContainer>
                  </StatementItemsContainer>
                  <StatementItemRemoveAction>
                    <IconButton
                      color="error"
                      onClick={() => {
                        if (formik.values.statementItems.length || isEdit) {
                          formik.setFieldValue(
                            "statementItems",
                            formik.values.statementItems.filter(
                              (_, i) => i !== index
                            ) // Remove the item
                          );
                        } 
                        if (formik.values.statementItems.length === 1 && !isEdit) {
                          formik.setFieldValue("statementItems", [
                            {
                              creditDebit: "",
                              amount: "",
                              foreignAmount: "",
                              description: "",
                              exchangeRate: "",
                              clientPartner: "",
                              clientId: "",
                              partnerId: "",
                              invoice: "",
                              reservationNumber: "",
                              totalAmount: "",
                              currencyId: "",
                            },
                          ]);
                        }
                      }}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StatementItemRemoveAction>
                </StatementItemRow>
                {index !== formik.values.statementItems.length - 1 && (
                  <Divider style={{ marginBottom: 24 }} />
                )}
              </React.Fragment>
            ))}
          <Box style={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() =>
                formik.setFieldValue("statementItems", [
                  ...formik.values.statementItems,
                  {
                    creditDebit: "",
                    amount: "",
                    foreignAmount: "",
                    description: "",
                    exchangeRate: "",
                    clientPartner: "",
                    clientId: "",
                    partnerId: "",
                    invoice: "",
                    reservationNumber: "",
                    statementId: "",
                    totalAmount: "",
                    currencyId: "",
                  },
                ])
              }
            >
              {t("statement.addNewItem")}
            </Button>
          </Box>
        </>
      </FormContainer>
      <FormActions
        disabled={!formik.dirty}
        handleConfirm={formik.handleSubmit}
        handleCancel={() => {
          navigate(PAGES.STATEMENTS.route);
        }}
      />
    </Box>
  );
};

StatementItemForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  date: PropTypes.string,
  statementId: PropTypes.number,
  statementType: PropTypes.number,
  isEdit: PropTypes.bool,
};

export default StatementItemForm;
