import React from 'react';
import { useLocation } from 'react-router-dom';
import { PAGES } from 'constants/pages';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';

function BreadcrumbsComponent({ customBreadcrumbs }) {
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split('/').filter((x) => x && isNaN(x));
  const pathnamesFull = pathname
    .split('/')
    .filter((x) => x)
    .map((segment) => (isNaN(segment) ? segment : ':id'));

  const findBreadcrumb = (path) => {
    const match = Object.values(PAGES).find((page) => page.route === path);
    return match ? match.breadcrumb : path;
  };

  if (customBreadcrumbs) {
    return (
      <Breadcrumbs
        sx={{ marginBottom: '24px' }}
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {customBreadcrumbs.map((element, index) =>
          index === customBreadcrumbs.length - 1 || !element.route ? (
            <Typography key={element.route || index} color="text.primary">
              {element.title}
            </Typography>
          ) : (
            <Link
              key={element.route || index}
              color="inherit"
              underline="hover"
              href={element.route}
            >
              {element.title}
            </Link>
          )
        )}
      </Breadcrumbs>
    );
  }
  
  return (
      <Breadcrumbs 
        sx={{ marginBottom: "24px", }} 
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link underline="hover" color="inherit" href={PAGES.BASE.route}>
          {PAGES.BASE.breadcrumb}
        </Link>
        {pathnames.map((_, index) => {
          const pathSegments = pathnames.slice(0, index + 1);
          const path = `/${pathSegments.join('/')}`;
          const breadcrumb = findBreadcrumb(
            pathnamesFull.length !== pathnames.length && index == pathnames.length - 1
            ? `/${pathnamesFull.join('/')}`
            :`/${pathSegments.join('/')}`
          );

          return index === pathnames.length - 1 ? (
            <Typography key={path} color="text.primary">
              {breadcrumb}
            </Typography>
          ) : (
            <Link key={path} underline="hover" color="inherit" href={path}>
              {breadcrumb}
            </Link>
          );
        })}
      </Breadcrumbs>
  );
}

BreadcrumbsComponent.propTypes = {
  customBreadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      route: PropTypes.string,
    })
  ),
};

export default BreadcrumbsComponent;
