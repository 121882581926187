import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import { useTranslation } from "react-i18next";
import TextInput from "components/Inputs/TextInput";
import DatePick from "components/Inputs/DatePick";
import SelectInput from "components/Inputs/SelectInput";
import { useStatementTypeDropdownQuery } from "features/statementType/statementTypeApiSlice";
import { useBankAccountDropdownQuery } from "features/bankAccount/bankAccountApiSlice";

const StatementForm = ({ formik, statementId, isEdit }) => {
  const { t } = useTranslation();

  const { data: statementTypes } = useStatementTypeDropdownQuery();
  const {data: bankAccounts, isLoading: isLoadingBankAccounts} = useBankAccountDropdownQuery();

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("statement.sections.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <SelectInput
            fullWidth
            name="statementTypeId"
            label={t("field.statementTypePlaceholderRequired")}
            value={formik.values.statementTypeId || ""}
            onChange={formik?.handleChange}
            error={
              formik.touched.statementTypeId &&
              Boolean(formik.errors.statementTypeId)
            }
            helperText={
              formik?.touched.statementTypeId && formik?.errors.statementTypeId
            }
            style={{ marginRight: "24px" }}
            items={statementTypes}
            disabled={isEdit}
          />
          <TextInput
            fullWidth
            name="number"
            type="number"
            label={t("field.statementNumberPlaceholderRequired")}
            value={formik.values.number || ""}
            onChange={formik?.handleChange}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik?.touched.number && formik?.errors.number}
            style={{ marginRight: "24px" }}
            disabled={isEdit}
          />
          <DatePick
            name="date"
            label={t("field.statementDatePlaceholderRequired")}
            value={formik.values.date}
            onChange={(value) => formik.setFieldValue("date", value)}
            style={{ marginRight: "24px" }}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
            maxDate={new Date()}
            disabled={isEdit}
          />
          <SelectInput
            fullWidth
            name="accountNumber"
            label={t("field.accountNumberPlaceholderRequired")}
            onChange={formik?.handleChange}
            value={formik.values.accountNumber || ""}
            error={
              formik.touched.accountNumber &&
              Boolean(formik.errors.accountNumber)
            }
            helperText={
              formik?.touched.accountNumber && formik?.errors.accountNumber
            }
            items={!isLoadingBankAccounts && bankAccounts}
            style={{ marginRight: !isEdit ? "24px" : "0" }}
            disabled={isEdit}
          />
          {!isEdit && !statementId && (
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={!formik.dirty}
              onClick={formik.handleSubmit}
            >
              Dodaj stavke izvoda
            </Button>
          )}
        </RowContainer>
      </FormContainer>
    </Box>
  );
};

StatementForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.string,
  isAdd: PropTypes.bool.isRequired,
  statementId: PropTypes.string,
  isEdit: PropTypes.bool.isRequired,
};

export default StatementForm;
