import React, { useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";
import { useTranslation } from "react-i18next";
import { PageContainer, RowContainer } from "styles/SharedStyle.styled";
import useDebtsTable from "hooks/tables/useDebtsTable";
import PropTypes from "prop-types";
import TextInput from "components/Inputs/TextInput";

const DebtsContentPage = ({ type }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { columns } = useDebtsTable({
    page,
    rowsPerPage,
  });

  let debts = [];

  if(type === 'clients')
  {
    debts = [
      {
        id: 1,
        type: 'Priznanica',
        invoiceReceiptNumber: '123-123',
        payer: 'test osoba',
        totalAmount: 100000.00,
        domesticCurrencyAmount: 1000.00,
        foreignCurrencyAmount: 10000.00,
        ExchangeRate: 117.19
      },
    ];
  }
  else
  {
    debts = [
      {
        id: 1,
        type: 'Faktura',
        invoiceReceiptNumber: '124-124',
        payer: 'test osoba',
        totalAmount: 100230.00,
        domesticCurrencyAmount: 1030.00,
        foreignCurrencyAmount: 10000.43,
        ExchangeRate: 117.19
      },
    ];
  }

  return (
    <PageContainer>
      <CustomTable
        columns={columns}
        data={debts}
        count={debts.count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("contract.debt.rowsName")}
        isLoading={false}
      />
      <RowContainer style={{ marginBottom: 24, marginTop: 24, justifyContent: "flex-end" }}>
        <TextInput
          name="domesticCurrencyAmount"
          label={t("contract.debt.input.domesticCurrencyAmount")}
          style={{ marginRight: "24px" }}
        />
        <TextInput
          name="foreignCurrencyAmount"
          label={t("contract.debt.input.foreignCurrencyAmount")}
          style={{ marginRight: "24px" }}
        />
      </RowContainer>
      <RowContainer style={{ marginBottom: 24, justifyContent: "flex-end"  }}>
        <TextInput
          name="sumPaidDomesticCurrency"
          label={t("contract.debt.input.sumPaidDomesticCurrency")}
          style={{ marginRight: "24px" }}
        />
        <TextInput
          name="sumPaidForeignCurrency"
          label={t("contract.debt.input.sumPaidForeignCurrency")}
          style={{ marginRight: "24px" }}
        />
      </RowContainer>
      <RowContainer style={{ marginBottom: 24, justifyContent: "flex-end"  }}>
        <TextInput
          name="toPayDomesticCurrency"
          label={t("contract.debt.input.toPayDomesticCurrency")}
          style={{ marginRight: "24px" }}
        />
        <TextInput
          name="toPayForeignCurrency"
          label={t("contract.debt.input.toPayForeignCurrency")}
          style={{ marginRight: "24px" }}
        />
      </RowContainer>
    </PageContainer>
  );
};

DebtsContentPage.propTypes = {
  type: PropTypes.string,
};

export default DebtsContentPage;
