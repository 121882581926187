export default {
  statementId: "",
  statementItems: [
    {
      clientPartner: "",
      creditDebit: "",
      amount: "",
      foreignAmount: "",
      description: "",
      exchangeRate: "",
      clientId: null,
      partnerId: null,
      invoice: "",
      reservationNumber: "",
      statementId: "",
      totalAmount: "",
      currencyId: "",
    },
  ],
};
