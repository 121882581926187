/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomTextFilter from "../CustomTextFilter";
import DateFilter from "../DateFilter";
import inputTypes from "constants/inputTypes";
import CustomAutoCompleteFilter from "../CustomAutoCompleteFilter";
import { useClientDropdownQuery } from "features/client/clientApiSlice";

const ReceiptsFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name="ReceiptNumber"
          type={inputTypes.NUMBER}
          label={t("field.receiptNumber")}
          filterForm={filterForm}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomTextFilter
          name="ContractNumber"
          type={inputTypes.NUMBER}
          label={t("field.contractNumberPlaceholder")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <DateFilter
          name="From"
          label={t("field.dateFrom")}
          filterForm={filterForm}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <DateFilter
          name="Till"
          label={t("field.dateTo")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomAutoCompleteFilter
          name="ClientId"
          label={t("field.client")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
          useQuery={useClientDropdownQuery}
        />
      </RowContainer>
    </Box>
  );
};

export default ReceiptsFilter;
