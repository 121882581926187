import React from "react";
import PropTypes from "prop-types";
import { useClientDetailsQuery } from "features/client/clientApiSlice";
import { ClientCardContainer, ClientCardInfo } from "./ClientCard.styled";
import { Typography } from "@mui/material";
import { useLocationDetailsQuery } from "features/location/locationApiSlice";
import { useTranslation } from "react-i18next";

const ClientCard = ({ clientId }) => {
  const { t } = useTranslation();
  const { data: client } = useClientDetailsQuery({
    id: clientId,
  });

  const { data: location } = useLocationDetailsQuery(
    { id: client?.locationId },
    { skip: !client }
  );

  return (
    <ClientCardContainer>
      <ClientCardInfo>
        <Typography variant="h6">{t("common.nameSurname")}</Typography>
        <Typography variant="body1">
          {client?.name} {client?.surname}
        </Typography>
      </ClientCardInfo>
      <ClientCardInfo>
        <Typography variant="h6">{t("common.address")}</Typography>
        <Typography variant="body1">{client?.address}</Typography>
      </ClientCardInfo>
      <ClientCardInfo>
        <Typography variant="h6">{t("common.location")}</Typography>
        <Typography variant="body1">{location?.name}{client?.postalCode ? `, ${client?.postalCode}` : ''}</Typography>
      </ClientCardInfo>
    </ClientCardContainer>
  );
};
ClientCard.propTypes = {
  clientId: PropTypes.number.isRequired,
};

export default ClientCard;
