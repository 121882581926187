/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import { useTranslation } from "react-i18next";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { useFormik } from "formik";
import useInvoiceTable from "hooks/tables/useInvoiceTable";
import InvoiceFilter from "components/Filter/InvoiceFilter/InvoiceFilter";
import Filter from "components/Filter/Filter";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import modalConstants from "constants/modalConstants";
import { closeModal } from "features/modal/modalSlice";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import BreadcrumbsComponent from "components/Breadcrumbs/BreadcrumbsComponent";
const InvoicePage = ({ isProInvoice }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const modals = useSelector((state) => state.modal.modals);
  const deleteModal = isProInvoice
    ? modals?.[modalConstants.DELETE_PROINVOICE_MODAL]
    : modals?.[modalConstants.DELETE_INVOICE_MODAL];

  const { columns } = useInvoiceTable({
    isProInvoice,
    page,
    rowsPerPage,
  });

  const [appliedFilters, setAppliedFilters] = useState({});
  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
    setAppliedFilters(getNonEmptyProps(values));
  };

  const filterForm = useFormik({
    initialValues: {
      Number: "",
      OnDate: null,
      ClientId: "",
    },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCancelConfirm = () => {
    if (deleteModal?.open) {
      dispatch(
        closeModal({
          id: isProInvoice
            ? modalConstants.DELETE_PROINVOICE_MODAL
            : modalConstants.DELETE_INVOICE_MODAL,
        })
      );
    }
  };

  const handleConfirm = () => {
    console.log("Confirm");
  };

  const breadcrumbs = [
    { title: t("breadcrumbs.home"), route: PAGES.BASE.route },
    { title: t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
    {
      title: isProInvoice
        ? t("breadcrumbs.proinvoice")
        : t("breadcrumbs.invoice"),
      route: "",
    },
  ];

  return (
    <PageContainer>
      <ConfirmModal
        open={deleteModal?.open}
        handleCancel={handleCancelConfirm}
        handleConfirm={handleConfirm}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={
          isProInvoice
            ? t("proinvoice.confirmDelete")
            : t("invoice.confirmDelete")
        }
      />
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {isProInvoice ? t("proinvoice.pageTitle") : t("invoice.pageTitle")}
      </Typography>
      <BreadcrumbsComponent customBreadcrumbs={breadcrumbs} />
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <InvoiceFilter
            filterForm={filterForm}
            isProInvoice={isProInvoice}
            appliedFilters={appliedFilters}
          />
        </Filter>
        <Button
          variant="contained"
          onClick={() =>
            isProInvoice
              ? navigate(PAGES.ADDPROINVOICE.route)
              : navigate(PAGES.ADDINVOICE.route)
          }
        >
          {isProInvoice ? t("proinvoice.addButton") : t("invoice.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={[]}
        isLoading={false}
        count={0}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={
          isProInvoice ? t("proinvoice.rowsName") : t("invoice.rowsName")
        }
        setSort={setSort}
      />
    </PageContainer>
  );
};

InvoicePage.propTypes = {
  isProInvoice: PropTypes.bool.isRequired,
};

export default InvoicePage;
