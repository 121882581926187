import React from "react";
import { paymentPageNavigationLinks } from "constants/navigationConstants";
import { PaymentPageCardContainer } from "./PaymentPage.styled";
import { PageContainer } from "styles/SharedStyle.styled";
import Title from "components/Title/Title";
import PageCard from "components/Cards/PageCard/PageCard";
import { useTranslation } from "react-i18next";

const PaymentPage = () => {
  const { t } = useTranslation();


  return (
    <PageContainer>
      <Title title={t("pages.payment")} />
      <PaymentPageCardContainer>
        {paymentPageNavigationLinks.map((link) => (
          <PageCard key={link.title} path={link.path} Icon={link.icon}>
          {link.title}
          </PageCard>
        ))}
      </PaymentPageCardContainer>
    </PageContainer>
  );
};

export default PaymentPage;