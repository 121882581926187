import React from "react";
import PropTypes from "prop-types";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import { useTranslation } from "react-i18next";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import tooltipPlacements from "constants/tooltipPlacements";

const CustomSelectFilter = (props) => {
  const { t } = useTranslation();
  const resetFilterValue = () => {
    if (props?.filterForm?.values?.[props?.name] !== "" && !props?.disabled) {
      props?.filterForm.setFieldValue(props.name, "");
      props?.resetFilterValue?.();
      if (
        props?.appliedFilters?.[props?.name] &&
        props?.appliedFilters?.[props?.name] !== ""
      ) {
        props?.filterForm.handleSubmit();
      }
    }
  };

  return (
    <SelectInput
      name={props.name}
      label={t(props.label)}
      onChange={props?.filterForm?.handleChange}
      value={props?.filterForm?.values?.[props?.name]}
      error={
        props?.filterForm?.touched?.[props?.name] &&
        Boolean(props?.filterForm?.errors?.[props?.name])
      }
      helperText={
        props?.filterForm?.touched?.[props?.name] &&
        props?.filterForm?.errors?.[props?.name]
      }
      items={props?.items}
      fullWidth
      disabled={props?.disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton disabled={props?.disabled} onClick={resetFilterValue}>
              <Tooltip
                title={t("common.resetFilter")}
                placement={tooltipPlacements.TOP}
              >
                <RestartAltIcon />
              </Tooltip>
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiSelect-icon": {
          marginRight: "45px",
        },
      }}
      {...props}
    />
  );
};

CustomSelectFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  filterForm: PropTypes.object,
  items: PropTypes.array,
  disabled: PropTypes.bool,
  resetFilterValue: PropTypes.func,
  InputLabelProps: PropTypes.object,
  appliedFilters: PropTypes.array,
};

export default CustomSelectFilter;
