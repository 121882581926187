import { Box } from "@mui/material";
import styled from "styled-components";

export const SalesPageCardContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  align-self: center;
  @media (max-width: 600px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-self: stretch;
  }
`;