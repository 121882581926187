import BreadcrumbsComponent from "components/Breadcrumbs/BreadcrumbsComponent";
import ReceiptForm from "components/Forms/ReceiptForm/ReceiptForm";
import Title from "components/Title/Title";
import { PAGES } from "constants/pages";
import { selectCurrentAgency } from "features/auth/authSlice";
import {
  useAddReceiptMutation,
  useEditReceiptMutation,
  useReceiptDetailsQuery,
} from "features/receipts/receiptsSlice";
import { useTemplateAgencyInfoGetByAgencyQuery } from "features/templateAgencyInfo/templateAgencyInfoApiSlice";
import { useFormik } from "formik";
import receiptsInitialValues from "initialValues/receiptsInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import receiptsValidation from "validations/receiptsValidation";

const AddEditReceiptPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDRECEIPT.route;
  const navigate = useNavigate();
  const agencyId = useSelector(selectCurrentAgency);
  const { data: receiptDescription } = useTemplateAgencyInfoGetByAgencyQuery({
    agencyId,
  });
  const [addReceipt, { isLoading: isLoadingAddReceipt }] =
    useAddReceiptMutation();
  const [editReceipt, { isLoading: isLoadingEditReceipt }] =
    useEditReceiptMutation();
  const { data: receiptDetails, isLoading: isLoadingReceiptDetails } =
    useReceiptDetailsQuery({ id }, { skip: isAdd });

  const handleSubmit = async (values) => {
    if (isAdd) {
      try {
        await addReceipt({
          ...values,
          date: convertDateToISO(values.date),
          cashAmount: values.cashAmount || 0,
          cardAmount: values.cardAmount || 0,
          checkAmount: values.checkAmount || 0,
        })
          .unwrap()
          .then(() => {
            if (!isLoadingAddReceipt) {
              navigate(PAGES.RECEIPTS.route);
              makeToastMessage(t("payment.addSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }

    if (id) {
      try {
        await editReceipt({
          ...values,
          date: convertDateToISO(values.date),
          cashAmount: values.cashAmount || 0,
          cardAmount: values.cardAmount || 0,
          checkAmount: values.checkAmount || 0,
        })
          .unwrap()
          .then(() => {
            if (!isLoadingEditReceipt) {
              navigate(PAGES.RECEIPTS.route);
              makeToastMessage(t("payment.editSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }
  };
  const formik = useFormik({
    initialValues:
      id && !isLoadingReceiptDetails
        ? {
            ...receiptDetails,
            date:
              receiptDetails?.date !== null
                ? new Date(receiptDetails?.date)
                : null,
            receiptNotice: receiptDetails?.receiptNotice !== null
              ? receiptDetails?.receiptNotice
              : receiptDescription?.receiptText,
          }
        : {
            ...receiptsInitialValues,
            receiptNotice: receiptDescription?.receiptText,
          },
    validationSchema: receiptsValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const breadcrumbs = [
    { title: t("breadcrumbs.home"), route: PAGES.BASE.route },
    { title: t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
    { title: t("breadcrumbs.receipts"), route: PAGES.RECEIPTS.route },
    {
      title: isAdd
        ? t("breadcrumbs.receiptsAdd")
        : t("breadcrumbs.receiptsEdit"),
      route: "",
    },
  ];
  return (
    <PageContainer>
      <Title
        title={
          isAdd
            ? t("payment.addTitle")
            : t("payment.editTitle", { number: receiptDetails?.receiptNumber })
        }
      />
      <BreadcrumbsComponent customBreadcrumbs={breadcrumbs} />
      <ReceiptForm formik={formik} />
    </PageContainer>
  );
};

export default AddEditReceiptPage;
