import i18n from "../i18nt/index";

export const PAGES = {
  BASE: {
    route: "/",
    title: i18n.t("pages.home"),
    breadcrumb: i18n.t("breadcrumbs.home"),
  },
  HOME: {
    route: "/home",
    title: i18n.t("pages.home"),
    breadcrumb: i18n.t("breadcrumbs.home"),
  },
  ADDAGENCY: {
    route: "/agency/add",
    title: i18n.t("pages.agency"),
    breadcrumb: i18n.t("breadcrumbs.agencyAdd"),
  },
  REGISTER: {
    route: "/register",
    title: i18n.t("pages.register"),
    breadcrumb: i18n.t("breadcrumbs.register"),
  },
  LOGIN: {
    route: "/login",
    title: i18n.t("pages.login"),
    breadcrumb: i18n.t("breadcrumbs.login"),
  },
  FORGOT_PASSWORD: {
    route: "/forgot-password",
    title: i18n.t("pages.forgotPassword"),
    breadcrumb: i18n.t("breadcrumbs.forgotPassword"),
  },
  ERROR: {
    route: "/error-page",
    title: i18n.t("pages.error"),
    breadcrumb: i18n.t("breadcrumbs.error"),
  },
  NOT_FOUND: {
    route: "/not-found",
    title: i18n.t("pages.notFound"),
    breadcrumb: i18n.t("breadcrumbs.notFound"),
  },
  PROFILE: {
    route: "/profile",
    title: i18n.t("pages.profile"),
    breadcrumb: i18n.t("breadcrumbs.profile"),
  },
  SETTINGS: {
    route: "/settings",
    title: i18n.t("pages.settings"),
    breadcrumb: i18n.t("breadcrumbs.settings"),
  },
  DASHBOARD: {
    route: "/dashboard",
    title: i18n.t("pages.dashboard"),
    breadcrumb: i18n.t("breadcrumbs.dashboard"),
  },
  CLIENTS: {
    route: "/clients",
    title: i18n.t("pages.clients"),
    breadcrumb: i18n.t("breadcrumbs.clients"),
  },
  ADDCLIENT: {
    route: "/clients/add",
    title: i18n.t("pages.clients"),
    breadcrumb: i18n.t("breadcrumbs.clientsAdd"),
  },
  EDITCLIENT: {
    route: "/clients/edit/:id",
    title: i18n.t("pages.clients"),
    breadcrumb: i18n.t("breadcrumbs.clientsEdit"),
  },
  ADDPARTNER: {
    route: "/partners/add",
    title: i18n.t("pages.partners"),
    breadcrumb: i18n.t("breadcrumbs.partnersAdd"),
  },
  PARTNERS: {
    route: "/partners",
    title: i18n.t("pages.partners"),
    breadcrumb: i18n.t("breadcrumbs.partners"),
  },
  EDITPARTNER: {
    route: "/partners/edit/:id",
    title: i18n.t("pages.partners"),
    breadcrumb: i18n.t("breadcrumbs.partnersEdit"),
  },
  ACCOMMODATION_UNIT: {
    route: "/accommodation/:id",
    title: i18n.t("pages.accommodationUnit"),
    breadcrumb: i18n.t("breadcrumbs.accommodationUnit"),
  },
  CODEBOOK: {
    route: "/codebook",
    title: i18n.t("pages.codebook"),
    breadcrumb: i18n.t("breadcrumbs.codebook"),
  },
  OFFERS: {
    route: "/offers",
    title: i18n.t("pages.offers"),
    breadcrumb: i18n.t("breadcrumbs.offers"),
  },
  ADDOFFER: {
    route: "/offers/add",
    title: i18n.t("pages.offers"),
    breadcrumb: i18n.t("breadcrumbs.offersAdd"),
  },
  EDITOFFER: {
    route: "/offers/edit/:id",
    title: i18n.t("pages.offers"),
    breadcrumb: i18n.t("breadcrumbs.offersEdit"),
  },
  SALES: {
    route: "/sales",
    title: i18n.t("pages.sales"),
    breadcrumb: i18n.t("breadcrumbs.sales"),
  },
  INTERNAL_SALE: {
    route: "/internal-sale",
    title: i18n.t("pages.internalSale"),
    breadcrumb: i18n.t("breadcrumbs.internalSale"),
  },
  EXTERNAL_SALE: {
    route: "/contract/external",
    title: i18n.t("pages.externalSale"),
    breadcrumb: i18n.t("breadcrumbs.externalSale"),
  },
  CONTRACTS: {
    route: "/contract",
    title: i18n.t("pages.contracts"),
    breadcrumb: i18n.t("breadcrumbs.contracts"),
  },
  SINGLE_CONTRACT: {
    route: "/contract/:id",
    title: i18n.t("pages.singleContract"),
    breadcrumb: i18n.t("breadcrumbs.singleContract"),
  },
  PAYMENT: {
    route: "/payment",
    title: i18n.t("pages.payment"),
    breadcrumb: i18n.t("breadcrumbs.payment"),
  },
  STATEMENTS: {
    route: "/statements",
    title: i18n.t("pages.excerpts"),
    breadcrumb: i18n.t("breadcrumbs.excerpts"),
  },
  REPORTS: {
    route: "/reports",
    title: i18n.t("pages.reports"),
    breadcrumb: i18n.t("breadcrumbs.reports"),
  },
  NOTIFICATIONS: {
    route: "/notifications",
    title: i18n.t("pages.notifications"),
    breadcrumb: i18n.t("breadcrumbs.notifications"),
  },
  ADMIN: {
    route: "/admin",
  },
  AGENCY: {
    route: "/agency/:id",
    breadcrumb: i18n.t("breadcrumbs.agency"),
  },
  CATEGORY: {
    route: "/category",
    title: i18n.t("pages.travelCategory"),
    breadcrumb: i18n.t("breadcrumbs.travelCategory"),
  },
  COUNTRY: {
    route: "/country",
    title: i18n.t("pages.country"),
    breadcrumb: i18n.t("breadcrumbs.country"),
  },
  LOCATION: {
    route: "/location",
    title: i18n.t("pages.city"),
    breadcrumb: i18n.t("breadcrumbs.city"),
  },
  ACCOMMODATION_TYPE: {
    route: "/accommodation-type",
    title: i18n.t("pages.objectType"),
    breadcrumb: i18n.t("breadcrumbs.objectType"),
  },
  ACCOMMODATION: {
    route: "/accommodation",
    title: i18n.t("pages.object"),
    breadcrumb: i18n.t("breadcrumbs.object"),
  },
  ACCOMMODATION_UNIT_TYPE: {
    route: "/accommodation-unit-type",
    title: i18n.t("pages.accommodationUnitType"),
    breadcrumb: i18n.t("breadcrumbs.accommodationUnitType"),
  },
  ACCOMMODATION_UNIT_PROPERTY: {
    route: "/unit-property",
    title: i18n.t("pages.accomodationUnitCharacteristics"),
    breadcrumb: i18n.t("breadcrumbs.accomodationUnitCharacteristics"),
  },
  SERVICES: {
    route: "/services",
    title: i18n.t("pages.serviceType"),
    breadcrumb: i18n.t("breadcrumbs.serviceType"),
  },
  TRANSPORTATION_TYPE: {
    route: "/transportation-type",
    title: i18n.t("pages.transportationType"),
    breadcrumb: i18n.t("breadcrumbs.transportationType"),
  },
  OFFER_TYPE: {
    route: "/offer-type",
    title: i18n.t("pages.offerType"),
    breadcrumb: i18n.t("breadcrumbs.offerType"),
  },
  RECEIPTS: {
    route: "/receipts",
    title: i18n.t("pages.receipts"),
    breadcrumb: i18n.t("breadcrumbs.receipts"),
  },
  INVOICE: {
    route: "/invoice",
    title: i18n.t("pages.invoice"),
    breadcrumb: i18n.t("breadcrumbs.invoice"),
  },
  PROINVOICE: {
    route: "/proinvoice",
    title: i18n.t("pages.proinvoice"),
    breadcrumb: i18n.t("breadcrumbs.proinvoice"),
  },
  EXCHANGE_RATE: {
    route: "/exchange-rate",
    title: i18n.t("pages.exchangeRate"),
    breadcrumb: i18n.t("breadcrumbs.exchangeRate"),
  },
  CURRENCY: {
    route: "/currency",
    title: i18n.t("pages.currency"),
    breadcrumb: i18n.t("breadcrumbs.currency"),
  },
  EDITUSER: {
    route: "/settings/user/edit/:id",
    title: i18n.t("pages.editUser"),
    breadcrumb: i18n.t("breadcrumbs.editUser"),
  },
  ADDUSER: {
    route: "/settings/user/add",
    title: i18n.t("pages.addUser"),
    breadcrumb: i18n.t("breadcrumbs.addUser"),
  },
  EDITUSERADMIN: {
    route: "/user/edit/:id",
    title: i18n.t("pages.editUser"),
    breadcrumb: i18n.t("breadcrumbs.editUser"),
  },
  ADDUSERADMIN: {
    route: "/user/add",
    title: i18n.t("pages."),
    breadcrumb: i18n.t("breadcrumbs.addUser"),
  },
  ADDLICENCE: {
    route: "/licence/add",
    title: i18n.t("pages.addLicence"),
    breadcrumb: i18n.t("breadcrumbs.addLicence"),
  },
  EDITLICENCE: {
    route: "/licence/edit/:id",
    title: i18n.t("pages.editLicence"),
    breadcrumb: i18n.t("breadcrumbs.editLicence"),
  },
  ADDRECEIPT: {
    route: "/receipts/add",
    title: i18n.t("pages.addReceipt"),
    breadcrumb: i18n.t("breadcrumbs.addReceipt"),
  },
  EDITRECEIPT: {
    route: "/receipts/edit/:id",
    title: i18n.t("pages.editReceipt"),
    breadcrumb: i18n.t("breadcrumbs.editReceipt"),
  },
  ADDSTATEMENT: {
    route: "/statements/add",
    title: i18n.t("pages.addReceipt"),
    breadcrumb: i18n.t("breadcrumbs.statementAdd"),
  },
  EDITSTATEMENT: {
    route: "/statements/edit/:id",
    title: i18n.t("pages.editReceipt"),
    breadcrumb: i18n.t("breadcrumbs.statementEdit"),
  },
  ADDINVOICE: {
    route: "/invoice/add",
    title: i18n.t("pages.addInvoice"),
    breadcrumb: i18n.t("breadcrumbs.addInvoice"),
  },
  EDITINVOICE: {
    route: "/invoice/edit/:id",
    title: i18n.t("pages.editInvoice"),
    breadcrumb: i18n.t("breadcrumbs.editInvoice"),
  },
  ADDPROINVOICE: {
    route: "/proinvoice/add",
    title: i18n.t("pages.addProinvoice"),
    breadcrumb: i18n.t("breadcrumbs.addProinvoice"),
  },
  EDITPROINVOICE: {
    route: "/proinvoice/edit/:id",
    title: i18n.t("pages.editProinvoice"),
    breadcrumb: i18n.t("breadcrumbs.editProinvoice"),
  },
  ADDBANKACCOUNT: {
    route: "/bank-account/add",
    title: i18n.t("pages.addBankAccount"),
    breadcrumb: i18n.t("breadcrumbs.addBankAccount"),
  },
  EDITBANKACCOUNT: {
    route: "/bank-account/edit/:id",
    title: i18n.t("pages.editBankAccount"),
    breadcrumb: i18n.t("breadcrumbs.editBankAccount"),
  },
  DEBTS: {
    route: "contract/debt/:contractId",
    title: i18n.t("pages.debts"),
  },
};
