/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { useUserRolesQuery } from "features/user/userApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomTextFilter from "../CustomTextFilter";
import CustomSelectFilter from "../CustomSelectFilter";

const UserFilter = ({ filterForm, appliedFilters }) => {
  const { data: userRoles } = useUserRolesQuery();
  const { t } = useTranslation();

  const userActive = [
    {
      value: true,
      text: t("common.userActive"),
    },
    {
      value: false,
      text: t("common.userInactive"),
    },
  ];

  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name="Name"
          label={t("field.name")}
          filterForm={filterForm}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomTextFilter
          name="Surname"
          label={t("field.surname")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="Active"
          label={t("field.userStatus")}
          filterForm={filterForm}
          items={userActive}
          style={{ marginRight: "24px" }}
          InputLabelProps={{
            shrink: filterForm.values.Active !== "",
          }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="RoleId"
          label={t("field.roleIdPlaceholder")}
          filterForm={filterForm}
          items={userRoles}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default UserFilter;
