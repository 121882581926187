import React from "react";
import PropTypes from "prop-types";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TextInput from "components/Inputs/TextInput";
import tooltipPlacements from "constants/tooltipPlacements";

const CustomTextFilter = (props) => {
  const { t } = useTranslation();
  const resetFilterValue = () => {
    if (props?.filterForm?.values?.[props?.name] !== "" && !props?.disabled) {
      props?.filterForm.setFieldValue(props.name, "");
      if (
        props?.appliedFilters?.[props?.name] &&
        props?.appliedFilters?.[props?.name] !== ""
      ) {
        props?.filterForm.handleSubmit();
      }
    }
  };

  return (
    <TextInput
      name={props.name}
      type={props.type}
      label={t(props.label)}
      onChange={props?.filterForm?.handleChange}
      value={props?.filterForm?.values?.[props?.name]}
      error={
        props?.filterForm?.touched?.[props?.name] &&
        Boolean(props?.filterForm?.errors?.[props?.name])
      }
      helperText={
        props?.filterForm?.touched?.[props?.name] &&
        props?.filterForm?.errors?.[props?.name]
      }
      fullWidth
      disabled={props?.disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton disabled={props?.disabled} onClick={resetFilterValue}>
              <Tooltip
                title={t("common.resetFilter")}
                placement={tooltipPlacements.TOP}
              >
                <RestartAltIcon />
              </Tooltip>
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

CustomTextFilter.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  filterForm: PropTypes.object,
  appliedFilters: PropTypes.array,
  disabled: PropTypes.bool,
};

export default CustomTextFilter;
