/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { useAgencyTypeQuery } from "features/agency-type/agencyTypeApiSlice";
import { useLicenceStatusDropdownQuery } from "features/licenceStatus/licenceStatusApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomTextFilter from "../CustomTextFilter";
import CustomSelectFilter from "../CustomSelectFilter";

const AgencyFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();
  const { data: licenceStatuses } = useLicenceStatusDropdownQuery();
  const { data: agencyTypes } = useAgencyTypeQuery();

  const modifiedStatuses =
    licenceStatuses &&
    licenceStatuses?.map((item) => ({
      ...item,
      value: item.value === 1 ? true : false,
    }));

  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name="Name"
          label={t("field.agencyNameFilterPlaceholder")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="Active"
          label={t("field.agencyStatusPlaceholder")}
          filterForm={filterForm}
          items={modifiedStatuses}
          style={{ marginRight: "24px" }}
          InputLabelProps={{
            shrink: filterForm.values.Active !== "",
          }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="AgencyTypeId"
          label={t("field.agencyTypePlaceholder")}
          filterForm={filterForm}
          items={agencyTypes}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default AgencyFilter;
