/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import CheckIcon from "@mui/icons-material/Check";
import { useSetDefaultCurrencyMutation } from "features/currency/currencyApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";

const useCurrencyTable = ({ type, page, rowsPerPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setDefaultCurrency, { isLoading: isLoadingSetDefaultCurrency }] =
    useSetDefaultCurrencyMutation();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: t("currency.table.name"),
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "mark",
      label: t("currency.table.mark"),
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const currency = arrayToObject(tableMeta.rowData, columns);
          return currency.mark.toUpperCase();
        },
      },
    },
    {
      name: "isDefault",
      label: t("currency.table.default"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const currency = arrayToObject(tableMeta.rowData, columns);
          return currency.isDefault && <CheckIcon />;
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              {!rowData.isDefault && (
                <Tooltip
                  title={t("tableCols.currency.tooltip.setToDefault")}
                  placement="top"
                  style={{ marginRight: 5 }}
                >
                  <IconButton
                    onClick={() => {
                      setDefaultCurrency({ id: rowData.id })
                        .unwrap()
                        .then(() => {
                          if (!isLoadingSetDefaultCurrency) {
                            makeToastMessage(
                              t("currency.setDefaultSuccessMessage")
                            );
                          }
                        })
                        .catch(() =>
                          makeErrorToastMessage(
                            t("currency.setDefaultErrorMessage")
                          )
                        );
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.EDIT_CODEBOOK_MODAL,
                        data: rowData,
                      })
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.DELETE_CODEBOOK_MODAL,
                        data: { id: rowData.id },
                      })
                    );
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useCurrencyTable;
