export default {
  name: "",
  agencyTypeId: "",
  address: "",
  locationId: "",
  countryId: "",
  phoneNumber: "",
  email: "",
  webAddress: "",
  pib: "",
  legalIdentificationNumber: "",
  personInCharge: "",
  systemLicenceStatus: "",
  systemLicenceExpirationDate: null,
  licenceNumber: "",
  licenceNumberExpirationDate: null,
  postalCode: "",
};
