import TabsComponent from "components/Tab/Tabs";
import React from "react";
import AgencyTab from "./AgencyTab";
import { PageContainer } from "styles/SharedStyle.styled";
import ListAddLicence from "components/List/ListAddLicence";
import { useLocation, useParams } from "react-router-dom";
import ListAddUser from "components/List/ListAddUser";
import { useTranslation } from "react-i18next";
import Title from "components/Title/Title";
import { useAgencyDetailsQuery } from "features/agency/agencyApiSlice";

const AdminAgencyPage = () => {
  const { t } = useTranslation();
  let { state } = useLocation();
  const tabId = state?.tabId;

  const { id } = useParams();
  const { data } = useAgencyDetailsQuery({id});

  const tabs = [
    {
      label: t("admin.tabs.agency"),
      component: <AgencyTab />,
    },
    {
      label: t("admin.tabs.users"),
      component: <ListAddUser id={id} />,
    },
    {
      label: t("admin.tabs.licences"),
      component: <ListAddLicence id={id} />,
    },
  ];

  return (
    <PageContainer>
      <Title title={`${t('agency.singleAgencyTitle')} - ${data?.name || ""}`} />
      <TabsComponent tabs={tabs} tabId={tabId} />
    </PageContainer>
  );
};

export default AdminAgencyPage;
