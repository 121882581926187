import { apiSlice } from "features/api/apiSlice";

export const currencyApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Currency, CurrencySingle"],
  endpoints: (builder) => ({
    currencyDropdown: builder.query({
      query: () => ({
        url: `/currency/dropdown`,
      }),
      providesTags: ["Currency"],
    }),
    currencyList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, filter, sort } = args;
        return {
          url:
            "/currency/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
              ...(filter && filter),
            }),
        };
      },
      providesTags: ["Currency"],
    }),
    addCurrency: builder.mutation({
      query: (data) => ({
        url: `/currency/add`,
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Currency"],
    }),
    editCurrency: builder.mutation({
      query: (data) => ({
        url: "/currency/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Currency", "CurrencySingle"],
    }),
    setDefaultCurrency: builder.mutation({
      query: (data) => ({
        url: "/currency/set-as-default",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Currency"],
    }),
    deleteCurrency: builder.mutation({
      query: (data) => ({
        url: "/currency",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Currency"],
    }),
  }),
});

export const {
  useCurrencyDropdownQuery,
  useCurrencyListQuery,
  useAddCurrencyMutation,
  useEditCurrencyMutation,
  useSetDefaultCurrencyMutation,
  useDeleteCurrencyMutation,
} = currencyApiSlice;
