/* eslint-disable react/prop-types */
import { Autocomplete, Box, Button, IconButton } from "@mui/material";
import DatePick from "components/Inputs/DatePick";
import TextInput from "components/Inputs/TextInput";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import SelectInput from "components/Inputs/SelectInput";
import {
  useExchangeRateDropdownQuery,
  useExchangeRateListQuery,
} from "features/exchange-rate/exchangeRateSlice";
import { convertDateToISO } from "util/dateHelpers";
import { useClientDropdownQuery } from "features/client/clientApiSlice";
import { useAgencyPartnerDropdownQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import ClientCard from "components/Cards/ClientCard/ClientCard";
import PartnerCard from "components/Cards/PartnerCard/PartnerCard";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const InvoiceForm = ({ formik, isProInvoice }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [curr, setCurr] = useState(null);
  const { data: exchangeRateDropdown } = useExchangeRateDropdownQuery();
  const { data: exchangeRate, isLoading: isLoadingExchangeRate } =
    useExchangeRateListQuery(
      {
        page: 0,
        rowsPerPage: 1,
        filter: {
          currencyId: curr?.value,
          OnDate: convertDateToISO(formik.values.date),
        },
      },
      { skip: !curr || curr?.text === "Rsd" || !formik.values.date }
    );

  const clientPartners = [
    { value: 1, text: "Partner" },
    { value: 2, text: "Klijent" },
  ];

  const { data: clients, isLoading: isLoadingClients } = useClientDropdownQuery(
    {},
    { skip: !formik.values.clientPartner || formik.values.clientPartner === 1 }
  );
  const { data: partners, isLoadingPartners } = useAgencyPartnerDropdownQuery(
    {},
    {
      skip: !formik.values.clientPartner || formik.values.clientPartner === 2,
    }
  );

  useEffect(() => {
    if (formik.values.currencyId) {
      const currency = exchangeRateDropdown.find(
        (currency) => currency.value === formik.values.currencyId
      );

      setCurr(currency);
    }
  }, [formik.values.currencyId]);

  useEffect(() => {
    if (!isLoadingExchangeRate && exchangeRate?.data?.length) {
      if (curr?.text !== "Rsd") {
        formik.setFieldValue("rate", exchangeRate.data[0].rate);
      }
    }
  }, [isLoadingExchangeRate, exchangeRate, curr]);

  const totalAmount = useMemo(() => {
    return formik.values.items
      ? formik.values.items.reduce((acc, item) => acc + item.total, 0)
      : 0;
  }, [formik.values.items]);

  useEffect(() => {
    if (totalAmount > 0) {
      formik.setFieldValue("totalAmount", totalAmount, false);
    }
  }, [totalAmount, formik]);

  useEffect(() => {
    formik.values.items.forEach((item, index) => {
      const total = parseFloat((item.price * item.quantity).toFixed(2));

      if (total > 0) {
        formik.setFieldValue(`items[${index}].total`, total, false);
      }
    });
  }, [formik.values.items]);

  const isValidInput = (value) => {
    const regex = /^(?!.*\..*\.)[1-9][0-9]*(\.[0-9]{0,2})?$/;
    return regex.test(value);
  };
  useEffect(() => {
    if (formik.values.currencyId) {
      if (curr?.text !== "Rsd" && !formik.values.rate) {
        formik.setFieldError("rate", t("field.invoiceRateRequired"));
      }
    }
  }, [formik.values.currencyId, curr]);

  useEffect(() => {
    if (formik.values.clientPartner === 1) {
      if (formik.values.partnerId === "") {
        formik.setFieldError("partnerId", t("field.invoicePartnerRequired"));
      }
    } else if (formik.values.clientPartner === 2) {
      if (formik.values.clientId === "") {
        formik.setFieldError("clientId", t("field.invoiceClientRequired"));
      }
    }
  }, [formik]);

  const handleCancel = () => {
    navigate(isProInvoice ? PAGES.PROINVOICE.route : PAGES.INVOICE.route);
  };

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {isProInvoice
              ? t("proinvoice.section.basicInfo")
              : t("invoice.section.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <Box
          sx={{ display: "flex", justifyContent: "space-between" }}
          fullWidth
        >
          <Box sx={{ flexGrow: 2, marginRight: "24px" }}>
            <RowContainer>
              <TextInput
                fullWidth
                name="number"
                label={
                  isProInvoice
                    ? t("field.proInvoiceNumberPlaceholderRequired")
                    : t("field.invoiceNumberPlaceholderRequired")
                }
                style={{ marginRight: "24px" }}
                onChange={formik.handleChange}
                value={formik.values.number}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
              />
              <DatePick
                name="date"
                label={
                  isProInvoice
                    ? t("field.proInvoiceDatePlaceholderRequired")
                    : t("field.invoiceDatePlaceholderRequired")
                }
                value={formik.values.date}
                onChange={(value) => formik.setFieldValue("date", value, true)}
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
              />
            </RowContainer>
            <RowContainer>
              <DatePick
                name="transactionDate"
                label={t("field.invoiceTransactionDatePlaceholderRequired")}
                value={new Date(formik.values.transactionDate)}
                style={{ marginRight: "24px" }}
                onChange={(value) =>
                  formik.setFieldValue("transactionDate", value, true)
                }
                error={
                  formik.touched.transactionDate &&
                  Boolean(formik.errors.transactionDate)
                }
                helperText={
                  formik.touched.transactionDate &&
                  formik.errors.transactionDate
                }
              />
              <DatePick
                name="dueDate"
                label={
                  isProInvoice
                    ? t("field.proInvoiceDueDatePlaceholderRequired")
                    : t("field.invoiceDueDatePlaceholderRequired")
                }
                value={formik.values.dueDate}
                onChange={(value) =>
                  formik.setFieldValue("dueDate", value, true)
                }
                error={formik.touched.dueDate && Boolean(formik.errors.dueDate)}
                helperText={formik.touched.dueDate && formik.errors.dueDate}
              />
            </RowContainer>
            <RowContainer>
              <SelectInput
                fullWidth
                name="currencyId"
                label={t("field.currencyPlaceholderRequired")}
                value={formik.values?.currencyId || ""}
                onChange={formik.handleChange}
                items={exchangeRateDropdown}
                style={{ marginRight: "24px" }}
                error={
                  formik.touched.currencyId && Boolean(formik.errors.currencyId)
                }
                helperText={
                  formik.touched.currencyId && formik.errors.currencyId
                }
              />
              <TextInput
                fullWidth
                name="rate"
                label={t("field.ratePlaceholder")}
                onChange={formik.handleChange}
                value={formik.values.rate}
                error={formik.touched.rate && Boolean(formik.errors.rate)}
                helperText={formik.touched.rate && formik.errors.rate}
              />
            </RowContainer>
            <RowContainer>
              <SelectInput
                fullWidth
                name="clientPartner"
                label={t("field.clientPartnerPlaceholderRequired")}
                value={formik.values.clientPartner}
                onChange={(e) => {
                  formik.setFieldValue("clientId", "");
                  formik.setFieldValue("partnerId", "");
                  formik.setFieldValue("clientPartner", e.target.value, true);
                }}
                items={clientPartners}
                style={{ marginRight: "24px" }}
                error={
                  formik.touched.clientPartner &&
                  Boolean(formik.errors.clientPartner)
                }
                helperText={
                  formik.touched.clientPartner && formik.errors.clientPartner
                }
              />
              <Autocomplete
                fullWidth
                id={
                  formik.values.clientPartner === 1
                    ? "partnerId"
                    : formik.values.clientPartner === 2
                    ? "clientId"
                    : ""
                }
                options={
                  formik.values.clientPartner === 1
                    ? (!isLoadingPartners && partners) || []
                    : formik.values.clientPartner === 2
                    ? (!isLoadingClients && clients) || []
                    : []
                }
                getOptionLabel={(option) => option.text}
                value={
                  formik.values.clientPartner === 1
                    ? partners?.find(
                        (option) => option.value === formik.values.partnerId
                      )
                    : formik.values.clientPartner === 2
                    ? clients?.find(
                        (option) => option.value === formik.values.clientId
                      )
                    : { text: "", value: "" }
                }
                onChange={(_, newValue) => {
                  if (formik.values.clientPartner === 1) {
                    formik.setFieldValue(
                      "partnerId",
                      newValue ? newValue.value : ""
                    );
                  } else if (formik.values.clientPartner === 2) {
                    formik.setFieldValue(
                      "clientId",
                      newValue ? newValue.value : ""
                    );
                  }
                }}
                // eslint-disable-next-line no-unused-vars
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    fullWidth
                    name={
                      formik.values.clientPartner === 1
                        ? "partnerId"
                        : formik.values.clientPartner === 2
                        ? "clientId"
                        : ""
                    }
                    label={
                      formik.values.clientPartner === 1
                        ? t("field.partnerPlaceholderRequired")
                        : formik.values.clientPartner === 2
                        ? t("field.clientPlaceholderRequired")
                        : ""
                    }
                    error={
                      formik.values.clientPartner === 1
                        ? formik.touched.partnerId &&
                          Boolean(formik.errors.partnerId)
                        : formik.values.clientPartner === 2
                        ? formik.touched.clientId &&
                          Boolean(formik.errors.clientId)
                        : ""
                    }
                    helperText={
                      formik.values.clientPartner === 1
                        ? formik.touched.partnerId && formik.errors.partnerId
                        : formik.values.clientPartner === 2
                        ? formik.touched.clientId && formik.errors.clientId
                        : ""
                    }
                  />
                )}
              />
            </RowContainer>
            <RowContainer>
              <TextInput
                multiline
                fullWidth
                minRows={3}
                maxRows={3}
                label={t("field.invoiceNote")}
              />
            </RowContainer>
          </Box>
          <Box sx={{ flexGrow: 1, maxWidth: "33%" }}>
            <RowContainer>
              <TextInput
                fullWidth
                name="location"
                label={
                  isProInvoice
                    ? t("field.proInvoiceLocationPlaceholderRequired")
                    : t("field.invoiceLocationPlaceholderRequired")
                }
                onChange={formik.handleChange}
                value={formik.values.location}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
              />
            </RowContainer>
            <RowContainer>
              <TextInput
                fullWidth
                name="contractNumber"
                label={t("field.contractNumberLowercase")}
                onChange={formik.handleChange}
                value={formik.values.basisBill}
              />
            </RowContainer>
            <RowContainer>
              {(formik.values.clientId || formik.values.partnerId) && (
                <FormContainer>
                  <InformationContainer>
                    <InformationMainText>
                      {formik.values.clientId
                        ? t("invoice.section.clientInfo")
                        : formik.values.partnerId
                        ? t("invoice.section.partnerInfo")
                        : ""}
                    </InformationMainText>
                  </InformationContainer>
                  {formik.values.clientId && (
                    <ClientCard clientId={formik.values.clientId} />
                  )}
                  {formik.values.partnerId && (
                    <PartnerCard
                      partnerId={formik.values.partnerId}
                      partnerOnly={
                        !partners.find(
                          (partner) => partner.value === formik.values.partnerId
                        )?.partnerOnly
                      }
                    />
                  )}
                </FormContainer>
              )}
            </RowContainer>
          </Box>
        </Box>
      </FormContainer>
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {isProInvoice
              ? t("proinvoice.section.itemsInfo")
              : t("invoice.section.itemsInfo")}
          </InformationMainText>
        </InformationContainer>
        <>
          {formik?.values.items &&
            formik.values.items.map((item, index) => (
              <RowContainer
                key={index}
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <TextInput
                  label="R.br."
                  value={index + 1}
                  style={{ marginRight: "24px", width: 300 }}
                  disabled
                />
                <TextInput
                  fullWidth
                  name="description"
                  label={t("field.invoiceItemDescriptionPlaceholderRequired")}
                  value={formik.values.items[index].description || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].description`,
                      e.target.value
                    )
                  }
                  error={
                    formik.touched.items?.[index]?.description &&
                    Boolean(formik.errors.items?.[index]?.description)
                  }
                  helperText={
                    formik.touched.items?.[index]?.description &&
                    formik.errors.items?.[index]?.description
                  }
                  style={{ marginRight: "24px" }}
                />
                <TextInput
                  fullWidth
                  type="number"
                  name="price"
                  label={t("field.invoiceItemPricePlaceholderRequired")}
                  value={formik.values.items[index].price || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue === "" || isValidInput(inputValue)) {
                      formik.setFieldValue(
                        `items[${index}].price`,
                        parseFloat(inputValue)
                      );
                    }
                  }}
                  style={{ marginRight: "24px" }}
                  error={
                    formik.touched.items?.[index]?.price &&
                    Boolean(formik.errors.items?.[index]?.price)
                  }
                  helperText={
                    formik.touched.items?.[index]?.price &&
                    formik.errors.items?.[index]?.price
                  }
                />
                <TextInput
                  type="number"
                  fullWidth
                  name="quantity"
                  label={t("field.invoiceItemQuantityPlaceholderRequired")}
                  value={formik.values.items[index].quantity || ""}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value > 0) {
                      formik.setFieldValue(`items[${index}].quantity`, value);
                    } else {
                      formik.setFieldValue(`items[${index}].quantity`, "");
                    }
                  }}
                  style={{ marginRight: "24px", width: 500 }}
                  error={
                    formik.touched.items?.[index]?.quantity &&
                    Boolean(formik.errors.items?.[index]?.quantity)
                  }
                  helperText={
                    formik.touched.items?.[index]?.quantity &&
                    formik.errors.items?.[index]?.quantity
                  }
                />

                <TextInput
                  type="number"
                  fullWidth
                  name="total"
                  label={t("field.ivoiceItemTotalPlaceholderRequired")}
                  value={formik.values.items[index].total || ""}
                  style={{ marginRight: "24px" }}
                  error={
                    formik.touched.items?.[index]?.total &&
                    Boolean(formik.errors.items?.[index]?.total)
                  }
                  helperText={
                    formik.touched.items?.[index]?.total &&
                    formik.errors.items?.[index]?.total
                  }
                />
                <IconButton
                  color="error"
                  onClick={() => {
                    if (formik.values.items.length > 1) {
                      formik.setFieldValue(
                        "items",
                        formik.values.items.filter((_, i) => i !== index) // Remove the item
                      );
                    } else {
                      formik.setFieldValue("items", [
                        {
                          description: "",
                          price: "",
                          quantity: 1,
                          total: "",
                        },
                      ]);
                    }
                  }}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </RowContainer>
            ))}
          <Box
            style={{
              display: "flex",
              alignItems: "flex-end",
              mt: 2,
              flexDirection: "column",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() =>
                formik.setFieldValue("items", [
                  ...formik.values.items,

                  {
                    description: "",
                    price: "",
                    quantity: 1,
                    total: "",
                  },
                ])
              }
              style={{ marginBottom: "24px" }}
            >
              {t("statement.addNewItem")}
            </Button>
            <TextInput
              type="number"
              name="totalAmount"
              label={t("field.invoiceTotalAmountPlaceholderRequired")}
              value={formik.values.totalAmount || ""}
              style={{ marginBottom: "24px" }}
              error={
                formik.touched.totalAmount && Boolean(formik.errors.totalAmount)
              }
              helperText={
                formik.touched.totalAmount && formik.errors.totalAmount
              }
            />
            <TextInput
              name="charTotalAmount"
              label={t("field.invoiceCharTotalAmoutPlaceholder")}
              value={formik.values.charTotalAmount || ""}
              onChange={formik.handleChange}
            />
          </Box>
        </>
      </FormContainer>
      <RowContainer>
        <TextInput
          fullWidth
          multiline
          minRows={3}
          maxRows={3}
          label={t("invoice.noteFromPrintSettings")}
          name="invoiceNotice"
          value={formik.values.invoiceNotice}
          onChange={formik.handleChange}
        />
      </RowContainer>
      <FormActions
        disabled={!formik.dirty}
        handleConfirm={formik.handleSubmit}
        handleCancel={handleCancel}
      />
    </Box>
  );
};

export default InvoiceForm;
