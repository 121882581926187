/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { usePartnerTypeDropdownQuery } from "features/partnerType/partnerTypeApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomTextFilter from "../CustomTextFilter";
import CustomSelectFilter from "../CustomSelectFilter";

const PartnerFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();
  const partnerStatuses = [
    { value: true, text: t("dropdown.activePartner") },
    { value: false, text: t("dropdown.inactivePartner") },
  ];
  const { data: partnerTypes } = usePartnerTypeDropdownQuery();
  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name="Name"
          label={t("field.partnerPlaceholder")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="PartnerTypeId"
          label={t("field.partnerTypePlaceholder")}
          filterForm={filterForm}
          items={partnerTypes}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="IsStatusActive"
          label={t("field.partnerStatusPlaceholder")}
          filterForm={filterForm}
          items={partnerStatuses}
          InputLabelProps={{
            shrink: filterForm.values.IsStatusActive !== "",
          }}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default PartnerFilter;
