import React from "react";
import { Route, Routes } from "react-router-dom";

import LoginPage from "./pages/LoginPage/LoginPage";
import HomePage from "pages/HomePage/HomePage";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import Error from "pages/ErrorPage/ErrorPage";
import { PAGES } from "constants/pages";
import ClientsPage from "pages/ClientsPage/ClientsPage";
import PartnersPage from "pages/PartnersPage/PartnersPage";
import OffersPage from "pages/OffersPage/OffersPage";
import SalesPage from "pages/SalesPage/SalesPage";
import PaymentPage from "pages/PaymentPage/PaymentPage";
import ReportsPage from "pages/ReportsPage/ReportsPage";
import NotificationsPage from "pages/NotificationsPage/NotificationsPage";
import AdminAgencyPage from "pages/AdminAgencyPage/AdminAgencyPage";
import CategoryPage from "pages/CategoryPage/CategoryPage";
import CountryPage from "pages/CountryPage/CountryPage";
import LocationPage from "pages/LocationPage/LocationPage";
import AccommodationTypePage from "pages/AccommodationTypePage/AccommodationTypePage";
import AccommodationUnitTypePage from "pages/AccommodationUnitTypePage/AccommodationUnitTypePage";
import ServicesPage from "pages/ServicesPage/ServicesPage";
import AccommodationPage from "pages/AccommodationPage/AccommodationPage";
import ReceiptsPage from "pages/ReceiptsPage/ReceiptsPage";
import InvoicePage from "pages/InvoicePage/InvoicePage";
import ExchangeRatePage from "pages/ExchangeRatePage/ExchangeRatePage";
import AuthRoute from "components/Router/AuthRoute";
import {
  CLERK_ROLE,
  MANAGER_ROLE,
  SUPERADMIN_ROLE,
} from "constants/roleConstants";
import AccommodationUnitPropertyPage from "pages/AccommodationUnitPropertyPage/AccommodationUnitPropertyPage";
import TransportationTypePage from "pages/TransportationTypePage/TransportationTypePage";
import OfferTypePage from "pages/OfferTypePage/OfferTypePage";
import AccommodationUnitPage from "pages/AccommodatiionUnitPage/AccommodationUnitPage";
import { OTP_TYPE } from "constants/agencyType";
import InternalSalePage from "pages/InternalSalePage/InternalSalePage";
import ContractsPage from "pages/ContractsPage/ContractsPage";
import SingleContractPage from "pages/SingleContractPage/SingleContractPage";
import CodebookPage from "pages/CodebookPage/CodebookPage";
import ClientAddEditPage from "pages/ClientsPage/ClientAddEditPage";
import PartnerAddEditPage from "pages/PartnersPage/PartnerAddEditPage";
import OfferAddEditPage from "pages/OffersPage/OfferAddEditPage";
import AddAgencyPage from "pages/AdminPage/AddAgencyPage";
import AddEditUserPage from "pages/SettingsPage/AddEditUserPage/AddEditUserPage";
import AddEditLicencePage from "pages/AdminAgencyPage/AddEditLicencePage";
import AddEditReceiptPage from "pages/ReceiptsPage/AddEditReceiptPage";
import StatementsPage from "pages/StatementsPage/StatementsPage";
import AddEditStatementPage from "pages/StatementsPage/AddEditStatementPage";
import AddEditInvoicePage from "pages/InvoicePage/AddEditInvoicePage";
import AddEditBankAccountPage from "pages/SettingsPage/AddEditBankAccountPage/AddEditBankAccountPage";
import ExternalSalePage from "pages/ExternalSalePage/ExternalSalePage";
import DebtsPage from "pages/ContractsPage/DebtsPage/DebtsPage";
import CurrencyPage from "pages/CurrencyPage/CurrencyPage";

const AppRoutes = () => (
  <Routes>
    <Route
      path={PAGES.LOGIN.route}
      element={<LoginPage />}
      errorElement={<Error />}
    />
    <Route
      element={<AuthRoute roles={[SUPERADMIN_ROLE]} />}
      errorElement={<Error />}
    >
      <Route path={PAGES.AGENCY.route} element={<AdminAgencyPage />} />
      <Route path={PAGES.ADDUSERADMIN.route} element={<AddEditUserPage />} />
      <Route path={PAGES.EDITUSERADMIN.route} element={<AddEditUserPage />} />
      <Route path={PAGES.ADDLICENCE.route} element={<AddEditLicencePage />} />
      <Route path={PAGES.EDITLICENCE.route} element={<AddEditLicencePage />} />
      <Route path={PAGES.ADDAGENCY.route} element={<AddAgencyPage />} />
      <Route path={PAGES.EXCHANGE_RATE.route} element={<ExchangeRatePage />} />
      <Route path={PAGES.CURRENCY.route} element={<CurrencyPage />} />
    </Route>
    <Route
      element={<AuthRoute roles={[MANAGER_ROLE]} />}
      errorElement={<Error />}
    >
      <Route path={PAGES.REPORTS.route} element={<ReportsPage />} />
    </Route>
    <Route
      element={<AuthRoute roles={[MANAGER_ROLE, CLERK_ROLE]} />}
      errorElement={<Error />}
    >
      <Route path={PAGES.REPORTS.route} element={<ReportsPage />} />
      <Route path={PAGES.SETTINGS.route} element={<SettingsPage />} />
      <Route path={PAGES.ADDUSER.route} element={<AddEditUserPage />} />
      <Route path={PAGES.EDITUSER.route} element={<AddEditUserPage />} />
      <Route path={PAGES.CLIENTS.route} element={<ClientsPage />} />
      <Route
        exact
        path={PAGES.ADDCLIENT.route}
        element={<ClientAddEditPage />}
      />
      <Route
        exact
        path={`${PAGES.EDITCLIENT.route}`}
        element={<ClientAddEditPage />}
      />
      <Route path={PAGES.PARTNERS.route} element={<PartnersPage />} />
      <Route path={PAGES.ADDPARTNER.route} element={<PartnerAddEditPage />} />
      <Route
        path={`${PAGES.EDITPARTNER.route}`}
        element={<PartnerAddEditPage />}
      />

      <Route path={PAGES.SALES.route} element={<SalesPage />} />
      <Route path={PAGES.INTERNAL_SALE.route} element={<InternalSalePage />} />
      <Route path={PAGES.EXTERNAL_SALE.route} element={<ExternalSalePage />} />
      <Route path={PAGES.CONTRACTS.route} element={<ContractsPage />} />
      <Route path={PAGES.PAYMENT.route} element={<PaymentPage />} />
      <Route path={PAGES.STATEMENTS.route} element={<StatementsPage />} />
      <Route
        path={PAGES.ADDSTATEMENT.route}
        element={<AddEditStatementPage />}
      />
      <Route
        path={PAGES.EDITSTATEMENT.route}
        element={<AddEditStatementPage />}
      />
      <Route path={PAGES.NOTIFICATIONS.route} element={<NotificationsPage />} />
      <Route path={PAGES.PROFILE.route} element={<ProfilePage />} />
      <Route
        path={PAGES.ACCOMMODATION_UNIT.route}
        element={<AccommodationUnitPage />}
      />
      <Route
        path={PAGES.SINGLE_CONTRACT.route}
        element={<SingleContractPage />}
      />
      <Route
        path={PAGES.ADDBANKACCOUNT.route}
        element={<AddEditBankAccountPage />}
      />
      <Route
        path={PAGES.EDITBANKACCOUNT.route}
        element={<AddEditBankAccountPage />}
      />
      <Route path={PAGES.CATEGORY.route} element={<CategoryPage />} />
      <Route path={PAGES.ACCOMMODATION.route} element={<AccommodationPage />} />
      <Route
        path={PAGES.ACCOMMODATION_UNIT_TYPE.route}
        element={<AccommodationUnitTypePage />}
      />
      <Route
        path={PAGES.ACCOMMODATION_UNIT_PROPERTY.route}
        element={<AccommodationUnitPropertyPage />}
      />
      <Route path={PAGES.SERVICES.route} element={<ServicesPage />} />
    </Route>

    <Route
      element={
        <AuthRoute roles={[MANAGER_ROLE, CLERK_ROLE]} agencyType={[OTP_TYPE]} />
      }
      errorElement={<Error />}
    >
      <Route path={PAGES.OFFERS.route} element={<OffersPage />} />
      <Route path={PAGES.ADDOFFER.route} element={<OfferAddEditPage />} />
      <Route path={PAGES.EDITOFFER.route} element={<OfferAddEditPage />} />
      <Route path={PAGES.CONTRACTS.route} element={<ContractsPage />} />
    </Route>

    <Route element={<AuthRoute roles={[]} />} errorElement={<Error />}>
      <Route path={PAGES.BASE.route} element={<HomePage />} />
      <Route path={PAGES.CODEBOOK.route} element={<CodebookPage />} />
      <Route path={PAGES.COUNTRY.route} element={<CountryPage />} />
      <Route path={PAGES.RECEIPTS.route} element={<ReceiptsPage />} />
      <Route path={PAGES.ADDRECEIPT.route} element={<AddEditReceiptPage />} />
      <Route path={PAGES.EDITRECEIPT.route} element={<AddEditReceiptPage />} />
      <Route path={PAGES.INVOICE.route} element={<InvoicePage />} />
      <Route
        path={PAGES.PROINVOICE.route}
        element={<InvoicePage isProInvoice />}
      />
      <Route
        path={PAGES.ADDPROINVOICE.route}
        element={<AddEditInvoicePage isProInvoice />}
      />
      <Route path={PAGES.ADDINVOICE.route} element={<AddEditInvoicePage />} />
      <Route
        path={PAGES.EDITPROINVOICE.route}
        element={<AddEditInvoicePage isProInvoice />}
      />
      <Route path={PAGES.EDITINVOICE.route} element={<AddEditInvoicePage />} />
      <Route path={PAGES.LOCATION.route} element={<LocationPage />} />
      <Route
        path={PAGES.ACCOMMODATION_TYPE.route}
        element={<AccommodationTypePage />}
      />
      <Route
        path={PAGES.TRANSPORTATION_TYPE.route}
        element={<TransportationTypePage />}
      />
      <Route path={PAGES.OFFER_TYPE.route} element={<OfferTypePage />} />
      <Route path={PAGES.DEBTS.route} element={<DebtsPage />} />
    </Route>

    <Route path="*" element={<Error />} />
  </Routes>
);

export default AppRoutes;
