export default {
    name: "",
    partnerTypeId: "",
    email: "",
    address: "",
    locationId: "",
    countryId: "",
    phoneNumber: "",
    pib: "",
    legalIdentificationNumber: "",
    postalCode: "",
  };
  