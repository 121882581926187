/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomSelectFilter from "../CustomSelectFilter";
import DateFilter from "../DateFilter";
import inputTypes from "constants/inputTypes";
import CustomTextFilter from "../CustomTextFilter";

const ContractFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name="Number"
          type={inputTypes.NUMBER}
          label={t("field.contractNumberPlaceholder")}
          filterForm={filterForm}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <DateFilter
          name="OnDate"
          label={t("field.travelDatePlaceholder")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="ClientId"
          label={t("field.clientSurnamePlaceholder")}
          filterForm={filterForm}
          items={[]}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="TravelOrganizerId"
          label={t("field.travelOrganizerPlaceholder")}
          filterForm={filterForm}
          items={[]}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default ContractFilter;
