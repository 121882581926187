import { PAGES } from "./pages";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CategoryIcon from "@mui/icons-material/Category";
import PublicIcon from "@mui/icons-material/Public";
import MapIcon from "@mui/icons-material/Map";
import HotelIcon from "@mui/icons-material/Hotel";
import SingleBedIcon from "@mui/icons-material/SingleBed";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import VillaIcon from "@mui/icons-material/Villa";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import OutboxIcon from "@mui/icons-material/Outbox";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import CurrencyIcon from "@mui/icons-material/AttachMoney";

export const headerNavigationConstants = [
  PAGES.HOME,
  PAGES.DASHBOARD,
  PAGES.PROFILE,
  PAGES.SETTINGS,
];

export const homePageNavigationLinks = [
  {
    title: PAGES.CLIENTS.title,
    path: PAGES.CLIENTS.route,
    icon: PeopleAltIcon,
  },
  {
    title: PAGES.OFFERS.title,
    path: PAGES.OFFERS.route,
    icon: LocalOfferIcon,
  },
  {
    title: PAGES.SALES.title,
    path: PAGES.SALES.route,
    icon: ShoppingCartIcon,
  },
  {
    title: PAGES.PAYMENT.title,
    path: PAGES.PAYMENT.route,
    icon: AttachMoneyIcon,
  },
  {
    title: PAGES.STATEMENTS.title,
    path: PAGES.STATEMENTS.route,
    icon: DescriptionIcon,
  },
  {
    title: PAGES.RECEIPTS.title,
    path: PAGES.RECEIPTS.route,
    icon: ReceiptIcon,
  },
];

export const codebookPageNavigationLinks = [
  {
    title: PAGES.CATEGORY.title,
    path: PAGES.CATEGORY.route,
    icon: CategoryIcon,
  },
  {
    title: PAGES.COUNTRY.title,
    path: PAGES.COUNTRY.route,
    icon: PublicIcon,
  },
  {
    title: PAGES.LOCATION.title,
    path: PAGES.LOCATION.route,
    icon: MapIcon,
  },
  {
    title: PAGES.ACCOMMODATION_TYPE.title,
    path: PAGES.ACCOMMODATION_TYPE.route,
    icon: SingleBedIcon,
  },
  {
    title: PAGES.ACCOMMODATION.title,
    path: PAGES.ACCOMMODATION.route,
    icon: HotelIcon,
  },
  {
    title: PAGES.ACCOMMODATION_UNIT_TYPE.title,
    path: PAGES.ACCOMMODATION_UNIT_TYPE.route,
    icon: VillaIcon,
  },
  {
    title: PAGES.ACCOMMODATION_UNIT_PROPERTY.title,
    path: PAGES.ACCOMMODATION_UNIT_PROPERTY.route,
    icon: MapsHomeWorkIcon,
  },
  {
    title: PAGES.SERVICES.title,
    path: PAGES.SERVICES.route,
    icon: RestaurantIcon,
  },
  {
    title: PAGES.TRANSPORTATION_TYPE.title,
    path: PAGES.TRANSPORTATION_TYPE.route,
    icon: AirportShuttleIcon,
  },
  {
    title: PAGES.OFFER_TYPE.title,
    path: PAGES.OFFER_TYPE.route,
    icon: CardGiftcardIcon,
  },
];

export const codebookPageNavigationLinksSuperAdmin = [
  {
    title: PAGES.COUNTRY.title,
    path: PAGES.COUNTRY.route,
    icon: PublicIcon,
  },
  {
    title: PAGES.LOCATION.title,
    path: PAGES.LOCATION.route,
    icon: MapIcon,
  },
  {
    title: PAGES.ACCOMMODATION_TYPE.title,
    path: PAGES.ACCOMMODATION_TYPE.route,
    icon: SingleBedIcon,
  },
  {
    title: PAGES.TRANSPORTATION_TYPE.title,
    path: PAGES.TRANSPORTATION_TYPE.route,
    icon: AirportShuttleIcon,
  },
  {
    title: PAGES.OFFER_TYPE.title,
    path: PAGES.OFFER_TYPE.route,
    icon: CardGiftcardIcon,
  },
  {
    title: PAGES.CURRENCY.title,
    path: PAGES.CURRENCY.route,
    icon: CurrencyIcon,
  },
];

export const salesPageNavigationLinks = [
  {
    title: PAGES.INTERNAL_SALE.title,
    path: PAGES.INTERNAL_SALE.route,
    icon: MoveToInboxIcon,
  },
  {
    title: PAGES.EXTERNAL_SALE.title,
    path: PAGES.EXTERNAL_SALE.route,
    icon: OutboxIcon,
  },
];

export const paymentPageNavigationLinks = [
  {
    title: PAGES.RECEIPTS.title,
    path: PAGES.RECEIPTS.route,
    icon: ReceiptIcon,
  },
  {
    title: PAGES.INVOICE.title,
    path: PAGES.INVOICE.route,
    icon: RequestPageIcon,
  },
  {
    title: PAGES.PROINVOICE.title,
    path: PAGES.PROINVOICE.route,
    icon: RequestPageOutlinedIcon,
  },
];