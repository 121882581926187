/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";

const useDebtsTable = ({
  page,
  rowsPerPage,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: "",
      label: t("tableCols.debts.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "type",
      label: t("tableCols.debts.invoiceReceiptLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "invoiceReceiptNumber",
      label: t("tableCols.debts.invoiceReceiptNumberLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "payer",
      label: t("tableCols.debts.payerLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalAmount",
      label: t("tableCols.debts.totalAmountLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "domesticCurrencyAmount",
      label: t("tableCols.debts.domesticCurrencyLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "foreignCurrencyAmount",
      label: t("tableCols.debts.foreignCurrencyLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "ExchangeRate",
      label: t("tableCols.debts.exchangeRateLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title={t('contract.debt.editLabel')}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    console.log('Placeholder for edit');
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t('contract.debt.deleteLabel')}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                      console.log('Placeholder for delete');
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useDebtsTable;
