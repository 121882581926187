/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { useExchangeRateDropdownQuery } from "features/exchange-rate/exchangeRateSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomSelectFilter from "../CustomSelectFilter";
import DateFilter from "../DateFilter";

const ExchangeRateFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();
  const { data: currencies } = useExchangeRateDropdownQuery();

  return (
    <Box component="form">
      <RowContainer>
        <DateFilter
          maxDate={new Date()}
          name="OnDate"
          label={t("field.dateFilter")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="CurrencyId"
          label={t("field.currencyFilter")}
          filterForm={filterForm}
          items={currencies}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default ExchangeRateFilter;
