export default {
  number: "",
  date: new Date(),
  location: "",
  transactionDate: new Date(),
  dueDate: null,
  contractNumber: "",
  currencyId: "",
  rate: "",
  clientPartner: "",
  clientId: "",
  partnerId: "",
  totalAmount: "",
  charTotalAmount: "",
  note: "",
  items: [
    {
      description: "",
      price: "",
      quantity: 1,
      total: "",
    },
  ],
};
