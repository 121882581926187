import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { Button } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import useUserTable from "hooks/tables/useUserTable";
import ResetPasswordModal from "components/Modals/ResetPasswordModal/ResetPasswordModal";
import resetPasswordInitialValues from "initialValues/resetPasswordInitialValues";
import resetPasswordValidation from "validations/resetPasswordValidation";
import { useTranslation } from "react-i18next";
import {
  useDeleteUserMutation,
  useEditPasswordMutation,
  useToggleStatusUserMutation,
  useUserListQuery,
} from "features/user/userApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import {
  ButtonContainer,
  ContentContainer,
  PageHeader,
} from "styles/SharedStyle.styled";
import useAuth from "hooks/useAuth";
import Filter from "components/Filter/Filter";
import UserFilter from "components/Filter/UserFilter/UserFilter";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAgency } from "features/auth/authSlice";
import modalConstants from "constants/modalConstants";
import { closeModal } from "features/modal/modalSlice";

const ListAddUser = ({ id }) => {
  const { isSuperAdmin } = useAuth();
  const dispatch = useDispatch();
  const agencyId = useSelector(selectCurrentAgency);
  const { isClerk } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const modals = useSelector((state) => state.modal.modals);
  const blockUserModal = modals?.[modalConstants.USER_BLOCK_MODAL];
  const deleteUserModal = modals?.[modalConstants.USER_DELETE_MODAL];
  const resetPasswordModal = modals?.[modalConstants.USER_RESET_PASSWORD_MODAL];

  const { columns } = useUserTable({
    page,
    rowsPerPage,
  });

  const [editUserPassword, { isLoading: isLoadingEditUserPassword }] =
    useEditPasswordMutation();
  const [toggleStatusUser, { isLoading: isLoadingToggleStatus }] =
    useToggleStatusUserMutation();

  const [deleteUser, { isLoading: isLoadingDeleteUser }] =
    useDeleteUserMutation();

  const { data: userList, isLoading: isLoadingUserList } = useUserListQuery({
    agencyId: isSuperAdmin ? id : agencyId,
    page,
    rowsPerPage,
    sort,
    filter,
  });

  const handleSubmitResetPassword = (val) => {
    editUserPassword({
      ...val,
      id: resetPasswordModal?.data?.id,
    })
      .unwrap()
      .then(() => {
        if (!isLoadingEditUserPassword) {
          resetPasswordFormik.resetForm();
          dispatch(
            closeModal({ id: modalConstants.USER_RESET_PASSWORD_MODAL })
          );
          makeToastMessage(t("users.passwordChangeSuccessMessage"));
        }
      })
      .catch(() =>
        makeErrorToastMessage(t("users.passwordChangeErrorMessage"))
      );
  };

  const [appliedFilters, setAppliedFilters] = useState({});
  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
    setAppliedFilters(getNonEmptyProps(values));
  };

  const resetPasswordFormik = useFormik({
    initialValues: resetPasswordInitialValues,
    validationSchema: resetPasswordValidation,
    onSubmit: handleSubmitResetPassword,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const filterForm = useFormik({
    initialValues: { Active: "", Name: "", Surname: "", RoleId: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCancelConfirm = () => {
    if (blockUserModal?.open) {
      dispatch(closeModal({ id: modalConstants.USER_BLOCK_MODAL }));
    }

    if (deleteUserModal?.open) {
      dispatch(closeModal({ id: modalConstants.USER_DELETE_MODAL }));
    }
  };

  const handleConfirm = () => {
    if (blockUserModal?.open) {
      toggleStatusUser({
        id: blockUserModal?.data.id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingToggleStatus) {
            dispatch(closeModal({ id: modalConstants.USER_BLOCK_MODAL }));
            makeToastMessage(t("users.toggleSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("users.toggleErrorMessage")));
    }

    if (deleteUserModal?.open) {
      deleteUser({
        id: deleteUserModal?.data.id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteUser) {
            dispatch(closeModal({ id: modalConstants.USER_DELETE_MODAL }));
            makeToastMessage(t("users.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("users.deleteErrorMessage")));
    }
  };

  const handleCloseBtn = () => {
    dispatch(closeModal({ id: modalConstants.USER_RESET_PASSWORD_MODAL }));
  };

  return (
    <ContentContainer>
      <ResetPasswordModal
        open={resetPasswordModal?.open}
        formik={resetPasswordFormik}
        handleCloseBtn={handleCloseBtn}
        handleClose={handleCloseBtn}
      />
      <ConfirmModal
        open={blockUserModal?.open || deleteUserModal?.open}
        handleCancel={handleCancelConfirm}
        handleConfirm={handleConfirm}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        btnConfirm={
          (blockUserModal?.open && blockUserModal?.data.userActive) ||
          deleteUserModal?.open
            ? "error"
            : "primary"
        }
        description={
          blockUserModal?.open
            ? t("users.confirmUserStatus", {
                status: blockUserModal?.data.userActive
                  ? t("users.deactivateUser")
                  : t("users.activateUser"),
              })
            : deleteUserModal?.open
            ? t("users.confirmDeleteUser")
            : ""
        }
      />
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <UserFilter filterForm={filterForm} appliedFilters={appliedFilters} />
        </Filter>
        {!isClerk && (
          <ButtonContainer>
            <Button
              variant="contained"
              onClick={() =>
                isSuperAdmin
                  ? navigate(PAGES.ADDUSERADMIN.route, {
                      state: { addAgencyId: id },
                    })
                  : navigate(PAGES.ADDUSER.route)
              }
            >
              {t("users.addButton")}
            </Button>
          </ButtonContainer>
        )}
      </PageHeader>
      <CustomTable
        columns={columns}
        data={userList?.data}
        count={userList?.totalCount}
        isLoading={isLoadingUserList}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("users.rowsName")}
      />
    </ContentContainer>
  );
};
ListAddUser.propTypes = {
  id: PropTypes.string.isRequired,
};
export default ListAddUser;
