/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { useFormik } from "formik";
import userSettingsInitialValues from "initialValues/userSettingsInitialValues";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { phoneNumberChars } from "util/helpers/preventNonNumericChars";
import userSettingsValidation from "validations/userSettingsValidation";
import {
  useEditUserMutation,
  useGetProfileImageQuery,
  useUploadProfileImageMutation,
  useDeleteProfileImageMutation,
} from "features/user/userApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import TextInput from "components/Inputs/TextInput";
import ImagePicker from "components/ImagePicker/ImagePicker";
import FormActions from "components/Forms/FormActions/FormActions";
import {
  UserSettingsBasicInfroContainer,
  UserSettingsFormContainer,
} from "./UserSettingsForm.styled";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import { useDispatch, useSelector } from "react-redux";
import modalConstants from "constants/modalConstants";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { closeModal, openModal } from "features/modal/modalSlice";

const UserSettingsForm = ({ userData, isLoadingUserData }) => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const deleteImageModal = modals?.[modalConstants.DELETE_IMAGE_MODAL];
  const { t } = useTranslation();
  const [editUser, { isLoading: isLoadingEditUser }] = useEditUserMutation();

  const handleSubmit = async (values) => {
    editUser(values)
      .unwrap()
      .then(() => {
        if (!isLoadingEditUser) {
          makeToastMessage(t("users.editSuccessMessage"));
        }
      })
      .catch(() => makeErrorToastMessage(t("users.editErrorMessage")));
  };

  const { data: image, isLoading: isLoadingProfileImage } =
    useGetProfileImageQuery();

  const formik = useFormik({
    initialValues:
      !isLoadingUserData || !isLoadingProfileImage
        ? {
            ...userData,
            profileImage: image?.data !== null ? [image?.data] : null,
          }
        : userSettingsInitialValues,
    validationSchema: userSettingsValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const [profileImage, setProfileImage] = useState(
    image?.data !== null ? [image?.data] : null
  );

  useEffect(() => {
    if (!isLoadingProfileImage) {
      setProfileImage(image?.data !== null ? [image?.data] : null);
    }
  }, [isLoadingProfileImage]);

  const [uploadImage, { isLoading: isLoadingUploadProfileImage }] =
    useUploadProfileImageMutation();

  const [deleteProfileImage, { isLoading: isLoadingDeleteProfileImage }] =
    useDeleteProfileImageMutation();

  const handleUploadProfileImage = (image) => {
    setProfileImage(image);
    uploadImage({ data: image[0] })
      .unwrap()
      .then(() => {
        makeToastMessage(t("profile.addProfileImageSuccessMessage"));
      })
      .catch(() =>
        makeErrorToastMessage(t("profile.addProfileImageErrorMessage"))
      );
  };

  const handleDelete = () => {
    if (deleteImageModal?.open) {
      deleteProfileImage()
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteProfileImage) {
            makeToastMessage(t("profile.deleteProfileImageSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("profile.deleteProfileImageErrorMessage"))
        );
      dispatch(closeModal({ id: modalConstants.DELETE_IMAGE_MODAL }));
      setProfileImage(null);
    }
  };

  const handleDeleteProfileImage = () => {
    dispatch(openModal({ id: modalConstants.DELETE_IMAGE_MODAL }));
  };

  const handleClose = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_IMAGE_MODAL }));
  };

  return (
    <Box
      style={{ marginTop: 32 }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <ConfirmModal
        open={deleteImageModal?.open}
        handleConfirm={handleDelete}
        handleCancel={handleClose}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("users.confirmDeleteImage")}
      />
      <UserSettingsFormContainer>
        <UserSettingsBasicInfroContainer>
          <FormContainer>
            <InformationContainer>
              <InformationMainText>
                {t("users.section.personalInfo")}
              </InformationMainText>
            </InformationContainer>
            <RowContainer>
              <TextInput
                fullWidth
                name="surname"
                label={t("field.surnamePlaceholder")}
                value={formik.values.surname}
                onChange={formik.handleChange}
                error={formik.touched.surname && Boolean(formik.errors.surname)}
                helperText={formik.touched.surname && formik.errors.surname}
                style={{ marginRight: "24px" }}
              />
              <TextInput
                fullWidth
                name="name"
                label={t("field.namePlaceholder")}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </RowContainer>
            <RowContainer style={{ marginBottom: 24 }}>
              <TextInput
                name="phoneNumber"
                fullWidth
                label={t("field.phoneNumberPlaceholder")}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                onKeyDown={phoneNumberChars}
                style={{ marginRight: "24px" }}
              />
              <TextInput
                fullWidth
                disabled
                name="email"
                label={t("field.emailPlaceholder")}
                value={formik.values.email}
              />
            </RowContainer>
          </FormContainer>
          <FormContainer>
            <InformationContainer>
              <InformationMainText>
                {t("users.section.agencyInfo")}
              </InformationMainText>
            </InformationContainer>
            <RowContainer>
              <TextInput
                fullWidth
                disabled
                name="office"
                label={t("field.officePlaceholder")}
                value={formik.values.office}
                style={{ marginRight: "24px" }}
              />
              <TextInput
                fullWidth
                disabled
                name="role"
                label={t("field.roleIdPlaceholder")}
                value={formik.values.role}
                onChange={formik.handleChange}
              />
            </RowContainer>
          </FormContainer>
        </UserSettingsBasicInfroContainer>
        <FormContainer>
          <InformationContainer>
            <InformationMainText>
              {t("users.section.profilePictureInfo")}
            </InformationMainText>
          </InformationContainer>
          <RowContainer>
            <ImagePicker
              descriptionText={t("profile.profilePicture")}
              additionalDescriptionText={t("common.pictureSize", { size: "2" })}
              label={""}
              images={profileImage}
              setImages={handleUploadProfileImage}
              name="profileImage"
              isLoading={
                isLoadingProfileImage ||
                isLoadingDeleteProfileImage ||
                isLoadingUploadProfileImage
              }
              handleDelete={handleDeleteProfileImage}
            />
          </RowContainer>
        </FormContainer>
      </UserSettingsFormContainer>
      <FormActions
        disabled={!formik.dirty}
        handleCancel={formik.resetForm}
        handleConfirm={formik.handleSubmit}
      />
    </Box>
  );
};

export default UserSettingsForm;
