/* eslint-disable react/prop-types */
import { Box, Button, Divider } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";
import {
  SectionTitle,
  Title,
} from "pages/SingleContractPage/SingleContractPage.styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import PassengerForm from "../PassengerForm/PassengerForm";
import DatePick from "components/Inputs/DatePick";
import ExternalTravelDetailsForm from "./ExternalTravelDetailsForm";
import ExternalCalculationForm from "./ExternalCalculationForm";
import BreadcrumbsComponent from "components/Breadcrumbs/BreadcrumbsComponent";
import { PAGES } from "constants/pages";

const ExternalContractForm = ({
  offerDetailsLevelId,
  formik,
  handleCancel,
  type,
}) => {
  const { t } = useTranslation();

  const { data: agencies, isLoading: isLoadingAgencies } =
    useAgencyDropdownQuery({});

    const breadcrumbs = [
      { title : t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title : t("breadcrumbs.sales"), route: PAGES.SALES.route },
      { title : t("breadcrumbs.externalSale"), route: '' },
    ];

  return (
    <Box component="form">
      <Title>{t("singleContract.pageTitle", { contractNumber: 2 })}</Title>
      <BreadcrumbsComponent customBreadcrumbs={breadcrumbs} />
      <RowContainer>
        <TextInput
          fullWidth
          name="number"
          label={t("field.contractNumberPlaceholderRequired")}
          value={formik.values?.number || ""}
          onChange={formik.handleChange}
          style={{ marginRight: "24px" }}
          error={formik.touched.number && Boolean(formik.errors.number)}
          helperText={formik.touched.number && formik.errors.number}
        />
        <DatePick
          name="date"
          label={t("field.contractDatePlaceholderRequired")}
          value={formik.values?.date}
          onChange={(value) => formik.setFieldValue("date", value, true)}
          error={formik.touched.date && Boolean(formik.errors.date)}
          helperText={formik.touched.date && formik.errors.date}
          style={{ marginRight: "24px" }}
        />
        <SelectInput
          fullWidth
          name="travelOrganizerId"
          label={t("field.travelOrganizerPlaceholderRequired")}
          value={formik.values?.travelOrganizerId || ""}
          style={{ marginRight: "24px" }}
          onChange={formik.handleChange}
          items={!isLoadingAgencies && agencies}
          error={
            formik.touched.travelOrganizerId &&
            Boolean(formik.errors.travelOrganizerId)
          }
          helperText={
            formik.touched.travelOrganizerId && formik.errors.travelOrganizerId
          }
        />
        <TextInput
          fullWidth
          name="jid"
          label={t("field.jidPlaceholder")}
          value={formik.values?.jid || ""}
          onChange={formik.handleChange}
        />
      </RowContainer>

      <Divider style={{ marginBottom: 24 }} />
      <SectionTitle>{t("singleContract.sections.travelDetails")}</SectionTitle>
      <ExternalTravelDetailsForm
        formik={formik}
        offerDetailsLevelId={offerDetailsLevelId}
        type={type}
      />

      <Divider style={{ marginBottom: 24 }} />
      <SectionTitle>
        {t("singleContract.sections.passengersDetails")}
      </SectionTitle>
      <PassengerForm formik={formik} />

      <Divider style={{ marginBottom: 24, marginTop: 24 }} />
      <SectionTitle>{t("singleContract.sections.calculation")}</SectionTitle>
      <ExternalCalculationForm formik={formik} />

      <Divider style={{ marginBottom: 24, marginTop: 24 }} />
      <SectionTitle>{t("singleContract.sections.description")}</SectionTitle>
      <TextInput
        fullWidth
        multiline
        minRows={4}
        maxRows={8}
        name="description"
        label={t("field.descriptionPlaceholder")}
        value={formik?.values?.description || ""}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
      />
      <RowContainer
        style={{ marginBottom: 0, justifyContent: "center", marginTop: 24 }}
      >
        <Button
          variant="outlined"
          onClick={handleCancel}
          style={{ marginRight: "24px" }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
        >
          {t("common.reserve")}
        </Button>
      </RowContainer>
    </Box>
  );
};

export default ExternalContractForm;
