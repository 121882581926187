import { apiSlice } from "features/api/apiSlice";

export const templateAgencyInfoApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["TemplateAgencyInfo"],
  endpoints: (builder) => ({
    templateAgencyInfoGet: builder.query({
      query: ({ id }) => ({
        url: `/template-agency-info/${id}`,
      }),
      providesTags: ["TemplateAgencyInfo"],
    }),
    templateAgencyInfoGetByAgency: builder.query({
      query: ({ agencyId }) => ({
        url: `/template-agency-info/agency/${agencyId}`,
      }),
      providesTags: ["TemplateAgencyInfo"],
    }),
    addTemplateAgencyInfoGet: builder.mutation({
      query: (data) => ({
        url: "/template-agency-info/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["TemplateAgencyInfo"],
    }),
    editTemplateAgencyInfoGet: builder.mutation({
      query: (data) => ({
        url: "/template-agency-info/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["TemplateAgencyInfo"],
    }),
    deleteTemplateAgencyInfoGet: builder.mutation({
      query: (data) => ({
        url: "/template-agency-info",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["TemplateAgencyInfo"],
    }),
  }),
});

export const {
  useTemplateAgencyInfoGetQuery,
  useTemplateAgencyInfoGetByAgencyQuery,
  useAddTemplateAgencyInfoGetMutation,
  useEditTemplateAgencyInfoGetMutation,
  useDeleteTemplateAgencyInfoGetMutation,
} = templateAgencyInfoApiSlice;
