/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React from "react";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomDialog from "../CustomDialog/CustomDialog";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useTranslation } from "react-i18next";
import { useAccommodationTypeDropdownQuery } from "features/accommodationType/accommodationTypeApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useOfferTypeDetailsLevelDropdownQuery } from "features/offerType/offerTypeApiSlice";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";
import AccommodationTypeSelect from "components/Inputs/AccommodationTypeSelect";

const AddEditCodebookItemModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  title,
  labelTitle,
  type,
}) => {
  const { t } = useTranslation();

  const { data: accommodationTypes } = useAccommodationTypeDropdownQuery(
    {},
    { skip: !open || type !== "accommodation" }
  );

  const { data: countries } = useCountryDropdownQuery(
    {},
    { skip: !open || (type !== "location" && type !== "accommodation") }
  );

  const { data: locations } = useLocationDropdownQuery(
    { id: formik.values.countryId },
    { skip: !open || !formik.values.countryId }
  );

  const { data: offerDetailsLevels } = useOfferTypeDetailsLevelDropdownQuery(
    {},
    { skip: !open || type !== "offerType" }
  );

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Box component="form">
        {type === "accommodation" && (
          <RowContainer>
            <AccommodationTypeSelect
              fullWidth
              name="accommodationTypeId"
              label={t("field.accommodationTypeIdPlaceholderRequired")}
              onChange={formik.handleChange}
              value={formik.values.accommodationTypeId}
              error={
                formik.touched.accommodationTypeId &&
                Boolean(formik.errors.accommodationTypeId)
              }
              helperText={
                formik.touched.accommodationTypeId &&
                formik.errors.accommodationTypeId
              }
              items={accommodationTypes}
            />
          </RowContainer>
        )}
        {(type === "accommodation" || type === "location") && (
          <RowContainer>
            <CountrySelect
              fullWidth
              name="countryId"
              label={t("field.countryIdPlaceholder")}
              onChange={formik.handleChange}
              value={formik.values.countryId || ""}
              error={
                formik.touched.countryId && Boolean(formik.errors.countryId)
              }
              helperText={formik.touched.countryId && formik.errors.countryId}
              items={countries}
            />
          </RowContainer>
        )}

        {type === "accommodation" && (
          <RowContainer>
            <LocationSelect
              fullWidth
              name="locationId"
              disabled={!formik.values.countryId}
              label={t("field.locationIdPlaceholder")}
              onChange={formik.handleChange}
              value={formik.values?.locationId || ""}
              error={
                formik.touched.locationId && Boolean(formik.errors.locationId)
              }
              helperText={formik.touched.locationId && formik.errors.locationId}
              items={locations}
              countryId={formik.values.countryId}
            />
          </RowContainer>
        )}

        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={labelTitle}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </RowContainer>

        {type === "currency" && (
          <RowContainer>
            <TextInput
              fullWidth
              name="mark"
              label={t("field.currencyMarkPlaceholderRequired")}
              value={formik.values.mark}
              onChange={formik.handleChange}
              error={formik.touched.mark && Boolean(formik.errors.mark)}
              helperText={formik.touched.mark && formik.errors.mark}
            />
          </RowContainer>
        )}

        {type === "offerType" && (
          <RowContainer>
            <SelectInput
              fullWidth
              name="offerDetailsLevelId"
              label={t("field.offerDetailsLevelPlaceholder")}
              onChange={formik.handleChange}
              value={formik.values.offerDetailsLevelId}
              error={
                formik.touched.offerDetailsLevelId &&
                Boolean(formik.errors.offerDetailsLevelId)
              }
              helperText={
                formik.touched.offerDetailsLevelId &&
                formik.errors.offerDetailsLevelId
              }
              items={offerDetailsLevels}
            />
          </RowContainer>
        )}

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddEditCodebookItemModal;
