/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import TextInput from "components/Inputs/TextInput";
import FormActions from "components/Forms/FormActions/FormActions";
import {
  ContentContainer,
  FormContainer,
  InformationContainer,
  InformationMainText,
} from "styles/SharedStyle.styled";
import { useAddTemplateAgencyInfoGetMutation, useEditTemplateAgencyInfoGetMutation } from "features/templateAgencyInfo/templateAgencyInfoApiSlice";
import { PAGES } from "constants/pages";
import { useNavigate } from "react-router-dom";

const UserSettingsForm = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editPrint, { isLoading: isLoadingEditData }] = useEditTemplateAgencyInfoGetMutation();
  const [addPrint, { isLoading: isLoadingAddData }] = useAddTemplateAgencyInfoGetMutation();
  const handleSubmit = async (values) => {
    if(values.id)
    {
        editPrint(values)
        .unwrap()
        .then(() => {
            if (!isLoadingEditData) {
            makeToastMessage(t("settings.printTab.editSuccessMessage"));
            }
        })
        .catch(() => makeErrorToastMessage(t("settings.printTab.editErrorMessage")));
    }
    else {
        addPrint(values)
        .unwrap()
        .then(() => {
            if (!isLoadingAddData) {
            makeToastMessage(t("settings.printTab.editSuccessMessage"));
            }
        })
        .catch(() => makeErrorToastMessage(t("settings.printTab.editErrorMessage")));
    }
  };

  const initialValues = {
    memorandumText: "",
    contractText: "",
    invoiceText: "",
    receiptText: "",
  };

  const formik = useFormik({
    initialValues:
      !isLoading && data
        ? {...data }
        : initialValues,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <ContentContainer
    component="form"
    onSubmit={formik.handleSubmit}
    >
      <FormContainer style={{ marginBottom: 24 }}>
        <InformationContainer>
          <InformationMainText>
            {t("settings.printTab.info")}
          </InformationMainText>
        </InformationContainer>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <TextInput
            name="memorandumText"
            multiline
            minRows={5}
            label={t("settings.printTab.documentHeader")}
            value={formik.values.memorandumText}
            onChange={formik.handleChange} 
            style={{ marginBottom: 24, marginTop: 5 }}
          />
          <TextInput
            name="contractText"
            multiline
            minRows={5}
            label={t("settings.printTab.defaultTextForContractNotes")}
            value={formik.values.contractText}
            onChange={formik.handleChange} 
            style={{ marginBottom: 24 }}
          />
          <TextInput
            name="invoiceText"
            multiline
            minRows={5}
            label={t("settings.printTab.defaultTextForInvoiceNotes")}
            value={formik.values.invoiceText}
            onChange={formik.handleChange} 
            style={{ marginBottom: 24 }}
          />
          <TextInput
            name="receiptText"
            multiline
            minRows={5}
            label={t("settings.printTab.defaultTextForReceiptNotes")}
            value={formik.values.receiptText}
            onChange={formik.handleChange} 
            style={{ marginBottom: 24 }}
          />
        </Box>
      </FormContainer>
      <FormActions
        disabled={isLoading || !formik.dirty}
        handleCancel={() => {navigate(PAGES.BASE.route)}}
        handleConfirm={formik.handleSubmit}
      />
    </ContentContainer>
  );
};

export default UserSettingsForm;
