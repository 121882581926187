import React from "react";
import PropTypes from "prop-types";
import { useAgencyPartnerGetInfoQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import { PartnerCardContainer, PartnerCardInfo } from "./PartnerCard.styled";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PartnerCard = ({ partnerId, partnerOnly }) => {
  const { t } = useTranslation();
  const {data: partner, error } = useAgencyPartnerGetInfoQuery({
    id: partnerId,
    internalPartner: partnerOnly,
  })

  return (
    <PartnerCardContainer>
      {error ? (
        <Typography variant="body1">{t("common.errorOccurred")}</Typography>
      ) : (
        <>
          <PartnerCardInfo>
            <Typography variant="h6">{t("common.partnerName")}</Typography>
            <Typography variant="body1">{partner?.name}</Typography>
          </PartnerCardInfo>
          <PartnerCardInfo>
            <Typography variant="h6">{t("common.address")}</Typography>
            <Typography variant="body1">{partner?.address}</Typography>
          </PartnerCardInfo>
          <PartnerCardInfo>
            <Typography variant="h6">{t("common.location")}</Typography>
            <Typography variant="body1">{partner?.location}{partner?.postalCode ? `, ${partner?.postalCode}` : ''}</Typography>
          </PartnerCardInfo>
          <PartnerCardInfo>
            <Typography variant="h6">{t("common.pibAndlegalIdentificationNumber")}</Typography>
            <Typography variant="body1">{`${partner?.pib}, ${partner?.legalIdentificationNumber}`}</Typography>
          </PartnerCardInfo>
        </>
      )}
    </PartnerCardContainer>
  );
};
PartnerCard.propTypes = {
  partnerId: PropTypes.number.isRequired,
  partnerOnly: PropTypes.bool.isRequired,
};

export default PartnerCard;
