/* eslint-disable no-unused-vars */
import BackdropComponent from "components/Backdrop/BackdropComponent";
import BreadcrumbsComponent from "components/Breadcrumbs/BreadcrumbsComponent";
import StatementForm from "components/Forms/StatementForm/StatementForm";
import StatementItemForm from "components/Forms/StatementForm/StatementItemForm";
import Title from "components/Title/Title";
import { PAGES } from "constants/pages";
import { DOMESTIC_TYPE, FOREIGN_TYPE } from "constants/statementType";
import { useAgencyPartnerDropdownQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import {
  useAddStatementMutation,
  useEditStatementMutation,
  useStatementDetailsQuery,
} from "features/statement/statementApiSlice";
import {
  useAddStatementItemMutation,
  useStatementItemListQuery,
} from "features/statementItem/statementItemApiSlice";
import { useFormik } from "formik";
import statementInitialValues from "initialValues/statementInitialValues";
import statementItemsInitialValues from "initialValues/statementItemsInitialValues";
import Error from "pages/ErrorPage/ErrorPage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import domesticStatementValidation from "validations/domesticStatementValidation";
import foreignStatementValidation from "validations/foreignStatementValidation";
import statementValidation from "validations/statementValidation";

const AddEditStatementPage = () => {
  const { id } = useParams();
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const isAdd = path === PAGES.ADDSTATEMENT.route;
  const { t } = useTranslation();
  const [statementId, setStatementId] = useState(null);

  const [addStatement, { isLoading: isLoadingAddStatement }] =
    useAddStatementMutation();
  const [editStatement, { isLoading: isLoadingEditStatement }] =
    useEditStatementMutation();
  const [addStatementItem, { isLoading: isLoadingAddStatementItem }] =
    useAddStatementItemMutation();
  const { data: statementItems } = useStatementItemListQuery(
    { statementId: id },
    { skip: isAdd }
  );
  const { data: partners, isLoading: isLoadingPartners } =
    useAgencyPartnerDropdownQuery({});
  const {
    data: statementDetails,
    isLoading: isLoadingStatementDetails,
    error,
  } = useStatementDetailsQuery(
    {
      id: isAdd ? statementId : id,
    },
    { skip: !statementId && !id }
  );

  const handleStatementSubmit = async (values) => {
    if (isAdd) {
      try {
        const statement = await addStatement({
          ...values,
          date: convertDateToISO(values.date),
        }).unwrap();

        if (statement && !isLoadingAddStatement) {
          setStatementId(statement?.id);
        }
      } catch (err) {
        makeErrorToastMessage(err.data.message);
      }
    }
  };

  function getFields(dataArray, statementType) {
    return dataArray.map((data) => {
      const { clientPartner, statementId, ...rest } = data;
      let result = { ...rest };

      result.statementId = Number(statementId);

      if (clientPartner === 1) {
        delete result.clientId;
        const partner = partners?.find(
          (partner) => partner.value === data.partnerId
        );
        if (partner) {
          result.partnerOnly = partner.partnerOnly;
        }
      } else if (clientPartner === 2) {
        delete result.partnerId;
      }

      if (statementType === DOMESTIC_TYPE) {
        delete result.foreignAmount;
        delete result.exchangeRate;
        delete result.totalAmount;
      } else if (statementType === FOREIGN_TYPE) {
        delete result.amount;
      }

      return result;
    });
  }

  const getStatementItemsFields = (dataArray) => {
    if (dataArray?.length) {
      return {
        statementId: "",
        statementItems: dataArray.map((data) => {
          const { clientId, partnerId, ...rest } = data;
          let result = { ...rest };
          if (clientId) {
            result.clientPartner = 2;
            result.clientId = clientId;
          } else if (partnerId) {
            result.clientPartner = 1;
            result.partnerId = partnerId;
          }
          const initialKeys = Object.keys(
            statementItemsInitialValues.statementItems[0]
          );
          Object.keys(result)?.forEach((key) => {
            if (!initialKeys.includes(key) && key !== "id") {
              delete result[key];
            }
          });
          return result;
        }),
      };
    }
    return statementItemsInitialValues;
  };

  const handleStatementItemsSubmit = (values) => {
    const vals = getFields(
      values.statementItems,
      formikStatement.values.statementTypeId
    );
    if (isAdd && formikStatement.dirty) {
      editStatement({
        id: formikStatement.values.id,
        date: convertDateToISO(formikStatement?.values.date),
        statementTypeId: formikStatement.values.statementTypeId,
        accountNumber: formikStatement.values.accountNumber,
        number: formikStatement.values.number,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingEditStatement) {
            addStatementItem({
              statementId: values.statementId,
              statementItems: vals,
            })
              .unwrap()
              .then(() => {
                if (!isLoadingAddStatementItem) {
                  if (isAdd) {
                    makeToastMessage(t("statementItem.addSuccessMessage"));
                    navigate(
                      PAGES.EDITSTATEMENT.route.replace(":id", statementId),
                      {
                        replace: true,
                      }
                    );
                  } else {
                    makeToastMessage(t("statementItem.editSuccessMessage"));
                    if (statementItems?.data.length === 0) {
                      formikStatementItem.resetForm();
                    }
                  }
                }
              })
              .catch((err) => {
                makeErrorToastMessage(err.data.message);
              });
          }
        })
        .catch((err) => {
          makeErrorToastMessage(err.data.message);
        });
    } else {
      addStatementItem({
        statementId: values.statementId,
        statementItems: vals,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingAddStatementItem) {
            if (isAdd) {
              makeToastMessage(t("statementItem.addSuccessMessage"));
              navigate(PAGES.EDITSTATEMENT.route.replace(":id", statementId), {
                replace: true,
              });
            } else {
              makeToastMessage(t("statementItem.editSuccessMessage"));
              if (statementItems?.data.length === 0) {
                formikStatementItem.resetForm();
              }
            }
          }
        })
        .catch((err) => {
          makeErrorToastMessage(err.data.message);
        });
    }
  };
  const formikStatement = useFormik({
    onSubmit: handleStatementSubmit,
    initialValues:
      (id || statementId) && !isLoadingStatementDetails
        ? { ...statementDetails, date: new Date(statementDetails?.date) }
        : statementInitialValues,
    validationSchema: statementValidation,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const formikStatementItem = useFormik({
    onSubmit: handleStatementItemsSubmit,
    initialValues: !isAdd
      ? getStatementItemsFields(statementItems?.data)
      : statementItemsInitialValues,
    validationSchema:
      formikStatement?.values.statementTypeId === DOMESTIC_TYPE
        ? domesticStatementValidation
        : formikStatement?.values.statementTypeId === FOREIGN_TYPE
        ? foreignStatementValidation
        : null,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (isLoadingStatementDetails) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingStatementDetails}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }

  return (
    <PageContainer>
      <Title title={id ? t("statement.editTitle") : t("statement.addTitle")} />
      <BreadcrumbsComponent />
      <StatementForm
        formik={formikStatement}
        statementId={statementId}
        isEdit={!isAdd}
      />
      {(statementId || statementDetails?.id) && (
        <StatementItemForm
          formik={formikStatementItem}
          date={formikStatement?.values.date}
          statementId={isAdd ? statementId : statementDetails?.id}
          statementType={formikStatement.values.statementTypeId}
          isEdit={!isAdd}
        />
      )}
    </PageContainer>
  );
};

export default AddEditStatementPage;
