import { Box } from "@mui/material";
import ImagePicker from "components/ImagePicker/ImagePicker";
import CountrySelect from "components/Inputs/CountrySelect";
import DatePick from "components/Inputs/DatePick";
import LocationSelect from "components/Inputs/LocationSelect";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import { useTranslation } from "react-i18next";
import { genders } from "constants/genderConstants";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { phoneNumberChars } from "util/helpers/preventNonNumericChars";
import {
  useDeletePassportImageMutation,
  useGetClientPassportImageQuery,
  useUploadPassportImageMutation,
} from "features/client/clientApiSlice";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { closeModal, openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { isAdult } from "util/dateHelpers";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const ClientForm = ({ formik, id, isAdd }) => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const deletePassportModal = modals?.[modalConstants.DELETE_IMAGE_MODAL];
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: image, isLoading: isLoadingClientPassportImage } =
    useGetClientPassportImageQuery({ id }, { skip: isAdd });

  const [passportImage, setPassportImage] = useState(
    image?.data !== null ? [image?.data] : null
  );
  const { data: countries } = useCountryDropdownQuery();
  const { data: locations } = useLocationDropdownQuery(
    {
      id: formik.values.countryId,
    },
    { skip: !formik.values.countryId }
  );

  useEffect(() => {
    if (!isLoadingClientPassportImage) {
      setPassportImage(image?.data !== null ? [image?.data] : null);
    }
  }, [isLoadingClientPassportImage]);

  const [uploadImage, { isLoading: isLoadingUploadPassportImage }] =
    useUploadPassportImageMutation();

  const [deletePassportImage, { isLoading: isLoadingDeletePassportImage }] =
    useDeletePassportImageMutation();

  const handleUploadPassportImage = (image) => {
    setPassportImage(image);
    uploadImage({ id, data: image[0] })
      .unwrap()
      .then(() => {
        makeToastMessage(t("client.addPassportImageSuccessMessage"));
      })
      .catch(() =>
        makeErrorToastMessage(t("client.addPassportImageErrorMessage"))
      );
  };

  const handleDeletePassportImage = () => {
    dispatch(openModal({ id: modalConstants.DELETE_IMAGE_MODAL }));
  };

  const handleDelete = () => {
    if (deletePassportModal?.open) {
      deletePassportImage({ clientId: id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeletePassportImage) {
            makeToastMessage(t("client.deletePassportImageSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("client.deletePassportImageErrorMessage"))
        );
      dispatch(closeModal({ id: modalConstants.DELETE_IMAGE_MODAL }));
      setPassportImage(null);
    }
  };

  const handleClose = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_IMAGE_MODAL }));
  };

  const handleCancel = () => {
    navigate(PAGES.CLIENTS.route);
  }

  return (
    <Box component="form">
      <ConfirmModal
        open={deletePassportModal?.open}
        handleConfirm={handleDelete}
        handleCancel={handleClose}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("users.confirmDeleteImage")}
      />
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("client.sections.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="surname"
            label={t("field.surnamePlaceholder")}
            value={formik.values.surname || ""}
            onChange={formik?.handleChange}
            error={formik.touched.surname && Boolean(formik.errors.surname)}
            helperText={formik?.touched.surname && formik?.errors.surname}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="name"
            label={t("field.namePlaceholder")}
            value={formik?.values.name || ""}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginRight: "24px" }}
          />
          <SelectInput
            fullWidth
            name="genderId"
            label={t("field.genderIdPlaceholder")}
            value={formik.values.genderId || ""}
            onChange={formik.handleChange}
            error={formik.touched.genderId && Boolean(formik.errors.genderId)}
            helperText={formik.touched.genderId && formik.errors.genderId}
            items={genders}
          />
        </RowContainer>

        <RowContainer>
          <CountrySelect
            fullWidth
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.countryId || ""}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            style={{ marginRight: "24px" }}
            items={countries}
          />
          <LocationSelect
            fullWidth
            name="locationId"
            disabled={!formik.values.countryId}
            label={t("field.locationIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values?.locationId || ""}
            error={
              formik.touched.locationId && Boolean(formik.errors.locationId)
            }
            helperText={formik.touched.locationId && formik.errors.locationId}
            items={locations}
            countryId={formik.values.countryId}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="address"
            label={t("field.addressPlaceholder")}
            value={formik.values.address || ""}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            style={{ marginRight: "24px" }}
          />

          <TextInput
            fullWidth
            name="postalCode"
            label={t("field.postalCodePlaceholder")}
            value={formik.values.postalCode || ""}
            onChange={formik.handleChange}
            error={
              formik.touched.postalCode && Boolean(formik.errors.postalCode)
            }
            helperText={formik.touched.postalCode && formik.errors.postalCode}
          />
        </RowContainer>

        <RowContainer>
          <DatePick
            name="dateOfBirth"
            label={t("field.dateOfBirthPlaceholder")}
            value={formik.values.dateOfBirth}
            onChange={(value) =>
              formik.setFieldValue("dateOfBirth", value, true)
            }
            error={
              formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
            }
            helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
            style={{ marginRight: "24px" }}
            maxDate={new Date()}
          />
          <TextInput
            name="phoneNumber"
            fullWidth
            label={
              formik.values.dateOfBirth &&
              isAdult(formik.values.dateOfBirth) >= 18
                ? t("field.phoneNumberRequiredPlaceholder")
                : t("field.phoneNumberPlaceholder")
            }
            value={formik.values.phoneNumber || ""}
            onChange={formik.handleChange}
            style={{ marginRight: "24px" }}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
          />
          <TextInput
            fullWidth
            name="email"
            label={t("field.emailNoRequiredPlaceholder")}
            value={formik.values?.email || ""}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </RowContainer>
      </FormContainer>

      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("client.sections.passportInfo")}
          </InformationMainText>
        </InformationContainer>
        <>
          <RowContainer>
            <TextInput
              fullWidth
              name="passportNumber"
              label={t("field.passportNumberPlaceholder")}
              value={formik.values.passportNumber || ""}
              onChange={formik.handleChange}
              style={{ marginRight: 24 }}
              error={
                formik.touched.passportNumber &&
                Boolean(formik.errors.passportNumber)
              }
              helperText={
                formik.touched.passportNumber && formik.errors.passportNumber
              }
            />
            <DatePick
              minDate={new Date()}
              name="passportExpirationDate"
              label={t("field.passportExpirationDatePlaceholder")}
              value={formik.values.passportExpirationDate}
              onChange={(value) =>
                formik.setFieldValue("passportExpirationDate", value, true)
              }
              error={
                formik.touched.licenceNumberExpirationDate &&
                Boolean(formik.errors.licenceNumberExpirationDate)
              }
              helperText={
                formik.touched.licenceNumberExpirationDate &&
                formik.errors.licenceNumberExpirationDate
              }
            />
          </RowContainer>
          {!isAdd && (
            <RowContainer>
              <ImagePicker
                descriptionText={t("client.passportImageDescriptionText")}
                additionalDescriptionText={t("common.pictureSize", {
                  size: "2",
                })}
                label={t("field.passportImagePlaceholder")}
                images={passportImage}
                setImages={handleUploadPassportImage}
                name="passportImage"
                isLoading={
                  isLoadingClientPassportImage ||
                  isLoadingDeletePassportImage ||
                  isLoadingUploadPassportImage
                }
                handleDelete={handleDeletePassportImage}
              />
            </RowContainer>
          )}
        </>
      </FormContainer>
      <FormActions
        disabled={!formik.dirty}
        handleCancel={handleCancel}
        handleConfirm={formik.handleSubmit}
      />
    </Box>
  );
};
ClientForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    setFieldError: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.string,
  isAdd: PropTypes.bool.isRequired,
};

export default ClientForm;
