import React from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import Title from "components/Title/Title";
import TabsComponent from "components/Tab/Tabs";
import DebtsContentPage from "./DebtsContentPage"

const DebtsPage = () => {
    const { t } = useTranslation();
    const { contractId } = useParams();
    const tabs = [
        {
          label: t("contract.debt.tabs.clientTab"),
          component: <DebtsContentPage type='clients'/>
        },
        {
          label: t("contract.debt.tabs.partnerTab"),
          component: <DebtsContentPage type='invoices'/>
        },
      ];
    return (
        <PageContainer>
            <Title title={t("contract.debt.title") + " " + contractId} />
            <TabsComponent tabs={tabs} tabId={0} />
        </PageContainer>
    );
};

export default DebtsPage;