/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { useAccommodationDropdownQuery } from "features/accommodation/accommodationApiSlice";
import { useCategoryDropdownQuery } from "features/category/categoryApiSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { useServiceDropdownQuery } from "features/service/serviceApiSlice";
import { useTransportationTypeDropdownQuery } from "features/transportationType/transportationTypeApiSlice";
import React from "react";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomSelectFilter from "../CustomSelectFilter";
import { useTranslation } from "react-i18next";

const OfferFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();
  const { data: offerTypes } = useOfferTypeDropdownQuery();
  const { data: accommodations } = useAccommodationDropdownQuery({});
  const { data: transportationTypes } = useTransportationTypeDropdownQuery();
  const { data: services } = useServiceDropdownQuery();
  const { data: categories } = useCategoryDropdownQuery();
  const offerStatuses = [
    { value: true, text: t("dropdown.activeOffer") },
    { value: false, text: t("dropdown.inactiveOffer") },
  ];
  return (
    <Box component="form">
      <RowContainer>
        <CustomSelectFilter
          name="OfferTypeId"
          label={t("field.offerTypePlaceholder")}
          filterForm={filterForm}
          items={offerTypes}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="AccommodationId"
          label={t("field.accommodationIdPlaceholder")}
          filterForm={filterForm}
          items={accommodations}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="TransportationTypeId"
          label={t("field.transportationTypePlaceholder")}
          filterForm={filterForm}
          items={transportationTypes}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="ServiceId"
          label={t("field.serviceIdPlaceholder")}
          filterForm={filterForm}
          items={services}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="CategoryId"
          label={t("field.categoryIdPlaceholder")}
          filterForm={filterForm}
          items={categories}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomSelectFilter
          name="Active"
          label={t("field.offerStatusPlaceholder")}
          filterForm={filterForm}
          items={offerStatuses}
          InputLabelProps={{
            shrink: filterForm.values.Active !== "",
          }}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default OfferFilter;
