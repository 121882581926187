/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomTextFilter from "../CustomTextFilter";

const CurrencyFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();
  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name="Name"
          label={t("field.currencyNamePlaceholder")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomTextFilter
          name="Mark"
          label={t("field.currencyMarkPlaceholder")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default CurrencyFilter;
